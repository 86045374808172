import React from 'react'
import {Messages} from "primereact/messages";

import tree1 from'./Tree_1.png';
import tree2 from'./Tree_2.png';
import tree3 from'./Tree_3.png';
import tree4 from'./Tree_4.png';
import tree5 from'./Tree_5.png';


class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            saved_paper: 100,
            time: 1,
        };
    }
    onSubmit = (event) => {
        event.preventDefault();
        this.setState({isLoading: true});
    };


    render() {

        function test(tree) {
            if (tree==='1'){
                return tree1;
            }else{
                if(tree==='2'){
                    return tree2;
                }else{
                    if(tree==='3'){
                        return tree3;
                    }else{
                        if(tree==='4'){
                            return tree4;
                        }else{
                            if(tree==='5') {
                                return tree5;
                            }
                        }return tree5;
                    }
                }
            }
        }

        return (

            <div>
                <main className="o-page-wrap  o-page-wrap--small">
                    <div className="o-page-wrap u-mb-large">
                        <header className="o-even-slimmer-page-wrap  u-text-center">
                            <h1>DNA saves Trees!</h1>
                            <p>Please insert the following values to get a price prediction:</p>
                            <br/>
                            <Messages ref={(el) => this.messages = el}></Messages>
                            <div id="login-form-banking"
                                 className="c-tab-content-container js-tab-container__content u-mb-none u-pb-none">
                                <form onSubmit={this.onSubmit}>
                                    <fieldset className="o-fieldset u-mb" style={{textAlign: "left"}}>
                                        <div className="o-split o-split--top" >
                                    <span>
                                        <h2>-</h2>
                                      <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="time">Time</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="time"
                                                           name="time" value={this.state.time}
                                                           onChange={(e) => {
                                                               this.setState({time: e.target.value})
                                                               this.setState({saved_paper: (e.target.value*100)})
                                                           }}/>
                                                </div>
                                            </div>

                                                <div className="o-layout__item  u-1/2">
                                                    <label htmlFor="saved_paper">Saved Paper</label>
                                                    <div className="c-input  ">
                                                        {this.state.saved_paper ?
                                                        <div className="c-card__body">
                                                            <p>{this.state.saved_paper}</p>
                                                        </div>
                                                        : null}
                                                    </div>
                                                </div>
                                                <div className="o-layout__item  u-1/2">
                                                    <div>
                                                        <div className="container">
                                                            <img src={test(this.state.time)} alt="fireSpot"/>
                                                        </div>
                                                    </div>
                                                </div>

                                        </div>
                                      </div>
                                    </span>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>

                        </header>
                    </div>
                </main>

            </div>
        );
    }
}
export default Main


