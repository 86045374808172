import React from 'react'
import axios from "axios"
import {Messages} from "primereact/messages";
import { getToken } from "../../utils";


class Demo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            aktenherkunft: "EVLG", //a
            aktenid: 2018024349, //a
            marke: "01", //VW
            modellcode: "3G532X", //1213CV
            modelljahr: "2018", // - 2016
            kraftstoff: "02", //BENZIN
            kw: 77, //100
            erstzulassung: "2017-09-12", //01.01.2015
            fahrleistung: 20000,
            schaden: 0.0, //0
            listenpreis_g: 32432.82, //15000
            listenpreis_b: 10000,
            listenpreis_f: 1000,
            listenpreis_m: 5000,
            icon_farbe: "8E", //a
            /*
            aktenherkunft: "EVLG",
            akten_id: 2018024349,
            marke_id: "01",
            modellcode: "3G532X",
            modelljahr: "2018",
            kraftstoff_suche: "02",
            kw: 77,
            erstzulassung: "2017-09-12",
            fahrleistung: 29090,
            schaeden: 2059.96,
            listenpreis_gesamt: 32432.82,
            farbe_finish: "8E",
            pimp_icons: ["ACAUTO", "BUSINESS", "NAVI", "WINTER", "AWD"]*/
        };
    }
    onSubmit = async (event) => {
        event.preventDefault();
        const pimp_icons_vals = ['ac', 'acauto', 'ahk', 'airsusp', 'alu', 'assi', 'aut', 'awd', 'business','def1','def2','def3','esd','gra','leder','navi','pdc','rearcam'
            ,'rline','shz','sline','sonder','sound','stdhzg','tel','truckreg','winter','xenon'];

        this.setState({isLoading: true});

        let values = [];
        let pos = 0

        //let checkedBoxes = document.querySelectorAll('input[type=checkbox]:checked');
        //for(let i = 0; i<checkedBoxes.length;i++){
        //  values = values + checkedBoxes[i].value + ';'
        //}
        for(let i = 0; i < pimp_icons_vals.length ;i++){
            let checkbox = document.getElementById(pimp_icons_vals[i]);
            if (checkbox.checked){
                values[pos] = checkbox.value
                pos++;
            }
            //values = values + checkbox.value + '", '
        }
        /*if (values.length > 0) {
            values = values.substring(0, values.length - 2);
            console.log(values);
        }*/

        // axios call
        const params = {
            aktenherkunft: this.state.aktenherkunft,
            akten_id: this.state.aktenid,
            marke_id: this.state.marke,
            modellcode: this.state.modellcode,
            modelljahr: this.state.modelljahr,
            kraftstoff_suche: this.state.kraftstoff,
            kw: this.state.kw,
            erstzulassung: this.state.erstzulassung,
            fahrleistung: this.state.fahrleistung,
            schaeden: this.state.schaden,
            listenpreis_gesamt: this.state.listenpreis_g,
            listenpreis_basis: this.state.listenpreis_b,
            listenpreis_farbe: this.state.listenpreis_f,
            listenpreis_mehrausstattung: this.state.listenpreis_m,
            farbe_finish: this.state.icon_farbe,
            pimp_icons: values
        };

        try {
            const token = await getToken();
            const axiosConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'x-service-id': process.env.REACT_APP_X_SERVICE_ID
                }
            };

            axios.post(process.env.REACT_APP_BE_URL, params, axiosConfig)
                .then(result => {
                    // array containing the models and details
                    // this.setState({result: JSON.parse(result.data)});
                    this.setState({result: result.data});
                    console.log(result.data);
                    this.setState({isLoading: false});
                })
                .catch(err => {
                    console.log(err);
                    this.messages.show({ severity: 'error', summary: 'Error', detail: err.message });
                    this.setState({isLoading: false});
                });
        } catch (e) {
            this.messages.show({ severity: 'error', summary: 'Error', detail: e.message });
        }

    };


    render() {
        return (
            <div>
                <main className="o-page-wrap  o-page-wrap--small">
                    <div className="o-page-wrap u-mb-large">
                        <header className="o-even-slimmer-page-wrap  u-text-center">
                            <h1>PROTOTYPE</h1>
                            <p>Please insert the following values to get a price prediction:</p>
                            <br/>
                            <Messages ref={(el) => this.messages = el}></Messages>

                            <div id="login-form-banking"
                                 className="c-tab-content-container js-tab-container__content u-mb-none u-pb-none">
                                <form onSubmit={this.onSubmit}>
                                    <fieldset className="o-fieldset u-mb" style={{textAlign: "left"}}>
                                        <div className="o-split o-split--top" >
                                    <span>
                                        <h2>Parameter</h2>
                                      <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="aktenherkunft">Aktenherkunft</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="aktenherkunft"
                                                           name="aktenherkunft" value={this.state.aktenherkunft}
                                                           onChange={(e) => {
                                                               this.setState({aktenherkunft: e.target.value})
                                                           }}/>
                                                </div>
                                            </div>

                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="schaden">Schaden</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="schaden"
                                                           name="schaden" value={this.state.schaden}
                                                           onChange={(e) => {
                                                               this.setState({schaden: e.target.value})
                                                           }} required/>
                                                </div>
                                            </div>

                                        </div>
                                        </div>

                                        <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="modelcode">Modell Code</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="modelcode"
                                                           name="modelcode" value={this.state.modellcode}
                                                           onChange={(e) => {
                                                               this.setState({modellcode: e.target.value})
                                                           }}/>
                                                </div>
                                            </div>

                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="listenpreis_g">Listenpreis Gesamt</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="listenpreis_g"
                                                           name="listenpreis_g" value={this.state.listenpreis_g}
                                                           onChange={(e) => {
                                                               this.setState({listenpreis_g: e.target.value})
                                                           }} required/>
                                                </div>
                                            </div>
                                            </div>
                                        </div>

                                        <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="erstzulassung">Erstzulassung</label>
                                                    <div className="c-input  ">
                                                   <input className="c-input__input" type="text" id="erstzulassung"
                                                          name="erstzulassung" value={this.state.erstzulassung}
                                                          onChange={(e) => {
                                                              this.setState({erstzulassung: e.target.value})
                                                          }} required/>
                                                    </div>
                                            </div>
                                             <div className="o-layout__item  u-1/2">
                                                <label htmlFor="icon_farbe">Farbe</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="icon_farbe"
                                                           name="icon_farbe" value={this.state.icon_farbe}
                                                           onChange={(e) => {
                                                               this.setState({icon_farbe: e.target.value})
                                                           }} required/>
                                                </div>
                                            </div>
                                        </div>
                                        </div>

                                        <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="fahrleistung">Fahrleistung</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="fahrleistung"
                                                           name="fahrleistung" value={this.state.fahrleistung}
                                                           onChange={(e) => {
                                                               this.setState({fahrleistung: e.target.value})
                                                           }} required/>
                                                </div>
                                            </div>
                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="modelljahr">Modelljahr</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="modelljahr"
                                                           name="modelljahr" value={this.state.modelljahr}
                                                           onChange={(e) => {
                                                               this.setState({modelljahr: e.target.value})
                                                           }} required/>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </span>
                                            <span style={{textAlign: "left", align:"left"}}>
                                            <h2>Resultat</h2>
                                            <article className="c-card c-card--large c-card--border" style={{textAlign: "left",  align:"left"}}>
                                                {this.state.result ?
                                                    <div className="c-card__body">
                                                        <p>Preis: {this.state.result.preisentscheidung} <span>€</span></p>
                                                        <p>Score: {this.state.result.konfidenzlevel}</p>
                                                    </div>
                                                    : null}
                                            </article>
                                        </span>
                                        </div>
                                        <br/>
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="ac" id="ac" value="AC"/>
                                                        <span className="c-checkbox__label">AC</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="acauto" id="acauto" value="ACAUTO"/>
                                                        <span className="c-checkbox__label">ACAUTO</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="ahk" id="ahk" value="AHK"/>
                                                        <span className="c-checkbox__label">AHK</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="airsusp" id="airsusp" value="AIRSUSP"/>
                                                        <span className="c-checkbox__label">AIRSUSP</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="alu" id="alu" value="ALU"/>
                                                        <span className="c-checkbox__label">ALU</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="assi" id="assi" value="ASSI"/>
                                                        <span className="c-checkbox__label">ASSI</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="aut" id="aut" value="AUT"/>
                                                        <span className="c-checkbox__label">AUT</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="awd" id="awd" value="AWD"/>
                                                        <span className="c-checkbox__label">AWD</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="business" id="business" value="BUSINESS"/>
                                                        <span className="c-checkbox__label">BUSINESS</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="def1" id="def1" value="DEF1"/>
                                                        <span className="c-checkbox__label">DEF1</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="def2" id="def2" value="DEF2"/>
                                                        <span className="c-checkbox__label">DEF2</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="def3" id="def3" value="DEF3"/>
                                                        <span className="c-checkbox__label">DEF3</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="esd" id="esd" value="ESD"/>
                                                        <span className="c-checkbox__label">ESD</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="gra" id="gra" value="GRA"/>
                                                        <span className="c-checkbox__label">GRA</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="leder" id="leder" value="LEDER"/>
                                                        <span className="c-checkbox__label">LEDER</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="navi" id="navi" value="NAVI"/>
                                                        <span className="c-checkbox__label">NAVI</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="pdc" id="pdc" value="PDC"/>
                                                        <span className="c-checkbox__label">PDC</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="rearcam" id="rearcam" value="REARCAM"/>
                                                        <span className="c-checkbox__label">REARCAM</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="rline" id="rline" value="RLINE"/>
                                                        <span className="c-checkbox__label">RLINE</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="shz" id="shz" value="SHZ"/>
                                                        <span className="c-checkbox__label">SHZ</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="sline" id="sline" value="SLINE"/>
                                                        <span className="c-checkbox__label">SLINE</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="sonder" id="sonder" value="SONDER"/>
                                                        <span className="c-checkbox__label">SONDER</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="sound" id="sound" value="SOUND"/>
                                                        <span className="c-checkbox__label">SOUND</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="stdhzg" id="stdhzg" value="STDHZG"/>
                                                        <span className="c-checkbox__label">STDHZG</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="tel" id="tel" value="TEL"/>
                                                        <span className="c-checkbox__label">TEL</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="truckreg" id="truckreg" value="TRUCKREG"/>
                                                        <span className="c-checkbox__label">TRUCKREG</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="winter" id="winter" value="WINTER"/>
                                                        <span className="c-checkbox__label">WINTER</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="xenon" id="xenon" value="XENON"/>
                                                        <span className="c-checkbox__label">XENON</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="o-split">
                                            <div className="o-fieldset__row">
                                                <div className="o-layout">
                                                    <div className="o-layout__item  u-1/1">
                                                        <button className="c-btn" type="submit"
                                                                disabled={this.state.isLoading}>
                                                            <span className="c-btn__text">Submit</span>
                                                            {this.state.isLoading ?
                                                                <div className="c-spinner c-spinner--small">
                                                                    <svg>
                                                                        <circle className="c-spinner__indicator" cx="9"
                                                                                cy="9"
                                                                                r="8"
                                                                                fill="none"/>
                                                                    </svg>

                                                                </div>
                                                                : null}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>

                        </header>
                    </div>
                </main>

            </div>
        );
    }
}
export default Demo