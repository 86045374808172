import React from "react";
import {Link} from 'react-router-dom';

class Footer extends React.Component {
    render() {
        return (
            <footer className="u-bg-brand u-pb" role="contentinfo">
                <div className="c-page-foot o-page-wrap">

                    <div className="c-page-foot__meta-nav o-split o-split--top u-mb">
                        <div className="u-text-white u-mb u-float-left@xs u-ws-nowrap"><br/>Volkswagen Financial Services AG - Data, Analytics & AI Unit
                        </div>
                    </div>

                    <div className="c-page-foot__meta-nav o-split o-split--collapse@s">
                        <nav>
                            <ul className="c-page-foot__meta-nav__list">
                                <li>
                                    <Link to="/impressum" className="c-mega-menu__link c-mega-menu__link--level-one">Impressum</Link>
                                </li>
                                <li>
                                    <Link to="/license" className="c-mega-menu__link c-mega-menu__link--level-one">License Notes</Link>
                                </li>
                            </ul>
                        </nav>

                        <div className="u-text-white u-mb u-ws-nowrap">
                            Version {this.props.version} © Volkswagen Financial Services <span>2018-2022</span>
                        </div>

                    </div>
                </div>
            </footer>
        );
    }
} export default Footer

