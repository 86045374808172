import React from 'react'
import axios from "axios";
import {Messages} from "primereact/messages";
import { getToken } from "../../utils";

class Audit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            aktenherkunft: 'EVOG',
            aktenid: 'a',
            marke: 'VW',
            modellcode: '3G525X',
            kraftstoff: 'BENZIN',
            kw: 100,
            modelljahr: '2016',
            erstzulassung: '2015-01-01',
            bepreisung: '2019-01-01',
            fahrleistung: 20000,
            schaden: 0,
            listenpreis_g: 15000,
            listenpreis_b: 10000,
            listenpreis_f: 1000,
            listenpreis_m: 5000,
            icon_farbe: 'Y1',
            versionid: 'uHwQ_CIUE95RjDCHc4vhSYILTWGUAB1_',
            versions: {},
        };

        this.search_version = this.search_version.bind(this);
    }

    componentDidMount() {
        getToken()
            .then((token) => {
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                        'x-service-id': process.env.REACT_APP_X_SERVICE_ID
                    }
                };

                axios.get(process.env.REACT_APP_BE_URL, axiosConfig)
                    .then(result => {
                        //console.log(versions);
                        this.setState({
                            isLoading: false,
                            versions: result.data
                        });
                    })
                    .catch(err => {
                        console.log(err);
                        this.messages.show({ severity: 'error', summary: 'Error', detail: err.message });
                        this.setState({isLoading: false});
                    });
            })
            .catch((e) => {
                this.messages.show({ severity: 'error', summary: 'Error', detail: e.message });
            });
    }

    search_version = (event) => {
        //if(event.key === 'Enter'){
        //  console.log('enter press here! ')
        //}
        let input, filter, table, tr, td, i, txtValue;
        input = document.getElementById("search-input-element");
        filter = input.value;//.toUpperCase();
        table = document.getElementById("version_table");
        tr = table.getElementsByTagName("tr");
        for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td")[1];
            if (td) {
                txtValue = td.textContent || td.innerText;
                if (txtValue.indexOf(filter) > -1) {
                    tr[i].style.display = "";
                } else {
                    tr[i].style.display = "none";
                }
            }
        }
    };

    onChange = (event) => {
        //let nav = document.getElementsByClassName("c-pagination");
        //console.log(nav);
        let pagination = document.getElementsByClassName("c-pagination__list");
        //console.log(pagination);
        let table = document.getElementById('version_table');
        let maxROwsdrodow = document.getElementById("maxRows");
        let tr = table.getElementsByTagName("tr");
        //var lastPage = 1;
        //pagination.childNodes.slice(1, -1).remove();
        //pagination.find("li").slice(1, -1).remove();
        var trnum = 0;									// reset tr counter
        var maxRows = parseInt(maxROwsdrodow.value);			// get Max Rows from select option
        //console.log(maxRows);
        //maxRows = maxRows; //add the title row
        //if (maxRows === 5000) {
        //  //pagination.hide();
        //  pagination[0].style.display = "none";
        //} else {
        //pagination.show();
        //  pagination[0].style.display = "block";
        //}
        //var totalRows = (table + ' tbody tr').length;		// numbers of rows
        var totalRows = tr.length;
        //console.log(totalRows);
        for (var i = 1; i < tr.length; i++) {
            trnum++;
            tr[0].style.display = "block"; //display always the title
            if (trnum > maxRows) {
                tr[i].style.display = "none";
            }
            if (trnum <= maxRows) {
                tr[i].style.display = "block";
            }
        }
        var pags = pagination[0].childNodes.length - 2; //discount prev and next child nodes
        if (totalRows > maxRows) {						// if tr total rows gt max rows option
                                                          //var mod = totalRows % maxRows;
            var pagenum = Math.ceil(totalRows / maxRows);	// ceil total(rows/maxrows) to get ..
            //console.log(pagenum);
            //console.log(pags);
            if (pagenum === 0){
                pagenum = 1;
            }
            //console.log(pagenum);
            //	numbers of pages
            //console.log(pagination[0].lastChild);
            //var pages = pagenum - pags;
            //console.log(pages);
            if(pagenum > pags){
                for (var i_a = 0; i_a < pagenum - pags;){			// for each page append pagination li
                    //var html = '<li data-page="'+i+'" class="c-pagination__item"><span>'+ i++ +'<span class="sr-only">(current)</span></span></li>';
                    //var html = '<li className="c-pagination__item"><a href="#" className="c-pagination__link">'+ i++ +'</a></li>';
                    //html = html.trim();
                    var newItem = document.createElement("li");
                    newItem.className = "c-pagination__item";
                    var newChild = document.createElement("a");
                    newChild.href = "";
                    newChild.className = "c-pagination__link";
                    newChild.innerText = pags++;
                    newChild.innerHTML = pags;
                    newChild.onclick = this.onClick;
                    newItem.appendChild(newChild);
                    pagination[0].insertBefore(newItem, pagination[0].lastChild).style.display = "block";
                }
                //nav[0].replaceChild(nav[0].childNodes[0], pagination)
            }
            else{
                var diff = pags - pagenum;
                //console.log(diff);
                if(diff > 0){
                    var numpage = pagination[0].childNodes.length - 2; //current numbers of pages
                    //console.log(numpage);
                    for(var i_l = 0; i_l< diff; i_l++){
                        pagination[0].removeChild(pagination[0].childNodes[numpage]);
                        numpage = numpage - 1;
                    }
                }
            }
        }
        else{
            pagenum = 1;
            var diff_p = pags - pagenum;
            //console.log(diff);
            if(diff_p > 0){
                var numpage_ = pagination[0].childNodes.length - 2; //current numbers of pages
                //console.log(numpage_);
                for(var i_i = 0; i_i< diff_p; i_i++){
                    pagination[0].removeChild(pagination[0].childNodes[numpage_]);
                    numpage_ = numpage_ - 1;
                }
            }
        }
    };

    onClick = (event) => {
        let pagination = document.getElementsByClassName("c-pagination__list");
        //let la = document.getElementsByClassName("c-pagination__link");
        let maxROwsdrodow = document.getElementById("maxRows");
        //let li = document.getElementsByName("li");
        let table = document.getElementById('version_table');
        let tr = table.getElementsByTagName("tr");
        //var lastPage = 1;

        //event.stopImmediatePropagation();
        event.stopPropagation();
        event.preventDefault();

        //console.log(pagination[0].childNodes.length);
        for(var i = 0; i < pagination[0].childNodes.length; i++){
            if(pagination[0].childNodes[i].childNodes[0].className === 'c-pagination__link  is-active'){
                var lastPage = pagination[0].childNodes[i].childNodes[0].textContent;
                //console.log(pageNum)
            }
        }
        //var pageNum = (this).attr('data-page').value();	// get it's number
        var pageNum = event.target.textContent;
        if(pageNum === ''){
            if (event.target.className === "c-pagination__link  c-pagination__link--stepper-prev"){
                if (lastPage === 1) {
                    pageNum = lastPage;
                }
                else{
                    pageNum = --lastPage;
                }
            }
            if(event.target.className === "c-pagination__link  c-pagination__link--stepper-next") {
                if (lastPage === (pagination[0].lastChild.length - 2)) {
                    pageNum = (pagination[0].lastChild.length - 2)
                }
                else{
                    pageNum = ++lastPage;
                }
            }
        }
        //console.log(event.target.className);
        //console.log(pageNum);
        var maxRows = parseInt(maxROwsdrodow.value);

        lastPage = pageNum;
        var trIndex = 0;
        //pagination[0].childNodes[1].removeClass('active');
        for(var i_k = 1; i_k < pagination[0].childNodes.length -1; i_k++){
            pagination[0].childNodes[i_k].childNodes[0].className = 'c-pagination__link'; // remove active class from all li
        }
        pagination[0].childNodes[lastPage].childNodes[0].className = 'c-pagination__link  is-active';  // add active class to the clicked
        //console.log(lastPage);
        //console.log(pagination);
        //var totalRows = tr.length;
        //console.log(totalRows);
        for (var i_p = 1; i_p < tr.length; i_p++) {
            trIndex++;
            tr[0].style.display = "block";
            if (trIndex > (maxRows * pageNum) || trIndex <= ((maxRows * pageNum) - maxRows)) {
                tr[i_p].style.display = "none";
            }
            else {
                tr[i_p].style.display = "block";
            }
        }
    };

    onSubmit = async (event) => {
        event.preventDefault();
        const pimp_icons_vals = ['ac', 'acauto', 'ahk', 'airsusp', 'alu', 'assi', 'aut', 'awd', 'business','def1','def2','def3','esd','gra','leder','navi','pdc','rearcam'
            ,'rline','shz','sline','sonder','sound','stdhzg','tel','truckreg','winter','xenon'];

        this.setState({isLoading: true});
        this.state.versionid.setState(document.querySelector('input[name="radio-button"]:checked').value);
        let values = '';

        for(let i = 0; i < pimp_icons_vals.length ;i++){
            let checkbox = document.getElementById(pimp_icons_vals[i]);
            if (checkbox.checked)
                values = values + checkbox.value + ';'
        }
        if (values.length > 0) {
            values = values.substring(0, values.length - 1);
            //console.log(values);
            //alert(versionid);
        }

        // axios call
        const params = {
            aktenherkunft: this.state.aktenherkunft,
            akten_id: this.state.aktenid,
            marke_id: this.state.marke,
            modellcode: this.state.modellcode,
            kraftstoff_suche: this.state.kraftstoff,
            kw: this.state.kw,
            modelljahr: this.state.modelljahr,
            erstzulassung: this.state.erstzulassung,
            bepreisung: this.state.bepreisung,
            fahrleistung: this.state.fahrleistung,
            schaeden: this.state.schaden,
            listenpreis_gesamt: this.state.listenpreis_g,
            farbe_finish: this.state.icon_farbe,
            versionid: this.state.versionid,
            pimp_icons: values
        };

        try {
            const token = await getToken();
            const axiosConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'x-service-id': process.env.REACT_APP_X_SERVICE_ID
                }
            };

            axios.post(process.env.REACT_APP_BE_URL, params, axiosConfig)
                .then(result => {
                    // array containing the models and details
                    //this.setState({result: JSON.parse(result.data)});
                    this.setState({result: result.data});
                    console.log(result.data);
                    this.setState({isLoading: false});
                })
                .catch(err => {
                    console.log(err);
                    this.messages.show({ severity: 'error', summary: 'Error', detail: err.message });
                    this.setState({isLoading: false});
                });
        } catch (e) {
            this.messages.show({ severity: 'error', summary: 'Error', detail: e.message });
        }
    };

    render() {
        return (
            <div>
                <main className="o-page-wrap  o-page-wrap--small">
                    <div className="o-page-wrap u-mb-large">
                        <header className="o-even-slimmer-page-wrap  u-text-center">
                            <h1>PROTOTYPE</h1>
                            <p>Please insert the following values to get a price prediction:</p>
                            <br/>
                        </header>
                        <Messages ref={(el) => this.messages = el}></Messages>

                        <label htmlFor="demo-input-element">Search using the format (YYYY-MM-DD)</label>
                        <div className="c-input">
                            <input className="c-input__input" id="search-input-element" name="search-input-element" type="text"  onKeyPress={this.search_version}/>
                        </div>
                        <div className="form-group">
                            <select className="form-control" name="state" id="maxRows" onChange={this.onChange}>
                                <option value="5000">Show ALL Rows</option>
                                <option value="3">3</option>
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        <br/>
                        <div className="c-table-wrapper">
                            <table id="version_table">
                                <caption>Default table for Model Versions</caption>
                                <thead>
                                <tr>
                                    <th>Select Version</th>
                                    <th>Version Date</th>
                                    <th>Version ID</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    Object.keys(this.state.versions).map((key, index) => (
                                        <tr>
                                            <td>
                                                <div className="o-inline-group">
                              <span className="o-inline-group__item">
                                <label className="c-radio">
                                    <input className="c-radio__input  " type="radio" id={this.state.versions[key]} value={this.state.versions[key]} name="radio-button"/>
                                    <span className="c-radio__label">Select</span>
                                </label>
                              </span>
                                                </div>
                                            </td>
                                            <td>{key}</td>
                                            <td>{this.state.versions[key]}</td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                        <nav className="c-pagination" aria-label="Pagination">
                            <ul  className="c-pagination__list">
                                <li className="c-pagination__item">
                                    <a className="c-pagination__link  c-pagination__link--stepper-prev" title="previous page" onClick={this.onClick}>
                                    </a>
                                </li>
                                <li className="c-pagination__item">
                                    <a className="c-pagination__link  is-active" onClick={this.onClick}>
                                        1
                                    </a>
                                </li>
                                <li className="c-pagination__item">
                                    <a className="c-pagination__link  c-pagination__link--stepper-next" title="next page" onClick={this.onClick}>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                        <div id="login-form-banking"
                             className="c-tab-content-container js-tab-container__content u-mb-none u-pb-none">
                            <form onSubmit={this.onSubmit}>
                                <fieldset className="o-fieldset u-mb" style={{textAlign: "left"}}>
                                    <div className="o-split o-split--top" >
                                    <span>
                                        <h2>Parameter</h2>
                                      <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="aktenherkunft">Aktenherkunft</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="aktenherkunft"
                                                           name="aktenherkunft" value={this.state.aktenherkunft}
                                                           onChange={(e) => {
                                                               this.setState({aktenherkunft: e.target.value})
                                                           }}/>
                                                </div>
                                            </div>

                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="schaden">Schaeden</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="schaden"
                                                           name="schaden" value={this.state.schaden}
                                                           onChange={(e) => {
                                                               this.setState({schaden: e.target.value})
                                                           }} required/>
                                                </div>
                                            </div>

                                        </div>
                                        </div>

                                        <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="modelcode">Modell Code</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="modelcode"
                                                           name="modelcode" value={this.state.modellcode}
                                                           onChange={(e) => {
                                                               this.setState({modellcode: e.target.value})
                                                           }}/>
                                                </div>
                                            </div>

                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="listenpreis_b">Listenpreis Gesamt</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="listenpreis_b"
                                                           name="listenpreis_b" value={this.state.listenpreis_g}
                                                           onChange={(e) => {
                                                               this.setState({listenpreis_g: e.target.value})
                                                           }} required/>
                                                </div>
                                            </div>
                                            </div>
                                        </div>

                                        <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="fahrleistung">Fahrleistung</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="fahrleistung"
                                                           name="fahrleistung" value={this.state.fahrleistung}
                                                           onChange={(e) => {
                                                               this.setState({fahrleistung: e.target.value})
                                                           }} required/>
                                                </div>
                                            </div>
                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="listenpreis_f">Farbe Finish</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="listenpreis_f"
                                                           name="listenpreis_f" value={this.state.icon_farbe}
                                                           onChange={(e) => {
                                                               this.setState({icon_farbe: e.target.value})
                                                           }} required/>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                       <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/3">
                                                <label htmlFor="erstzulassung">Erstzulassung</label>
                                                    <div className="c-input  ">
                                                   <input className="c-input__input" type="text" id="erstzulassung"
                                                          name="erstzulassung" value={this.state.erstzulassung}
                                                          onChange={(e) => {
                                                              this.setState({erstzulassung: e.target.value})
                                                          }} required/>
                                                    </div>
                                            </div>
                                          <div className="o-layout__item  u-1/3">
                                                <label htmlFor="erstzulassung">Datum der Bepreisung</label>
                                                    <div className="c-input  ">
                                                   <input className="c-input__input" type="text" id="erstzulassung"
                                                          name="erstzulassung" value={this.state.bepreisung}
                                                          onChange={(e) => {
                                                              this.setState({bepreisung: e.target.value})
                                                          }} required/>
                                                    </div>
                                            </div>
                                             <div className="o-layout__item  u-1/3">
                                                <label htmlFor="listenpreis_m">Modelljahr</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="listenpreis_m"
                                                           name="listenpreis_m" value={this.state.modelljahr}
                                                           onChange={(e) => {
                                                               this.setState({modelljahr: e.target.value})
                                                           }} required/>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </span>
                                        <span style={{textAlign: "left", align:"left"}}>
                                            <h2>Resultat</h2>
                                            <article className="c-card c-card--large c-card--border" style={{textAlign: "left",  align:"left"}}>
                                                {this.state.result ?
                                                    <div className="c-card__body">
                                                        <p>Preis: {this.state.result.preisentscheidung} <span>€</span></p>
                                                        <p>Score: {this.state.result.konfidenzlevel}</p>
                                                    </div>
                                                    : null}
                                            </article>
                                        </span>
                                    </div>
                                    <br/>
                                    <div className="o-layout">
                                        <div className="o-layout__item  u-1/6">
                                            <label className="c-checkbox">
                                                <div className="c-input">
                                                    <input className="c-checkbox__input  " type="checkbox" name="ac" id="ac" value="AC"/>
                                                    <span className="c-checkbox__label">AC</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="o-layout__item  u-1/6">
                                            <label className="c-checkbox">
                                                <div className="c-input">
                                                    <input className="c-checkbox__input  " type="checkbox" name="acauto" id="acauto" value="ACAUTO"/>
                                                    <span className="c-checkbox__label">ACAUTO</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="o-layout__item  u-1/6">
                                            <label className="c-checkbox">
                                                <div className="c-input">
                                                    <input className="c-checkbox__input  " type="checkbox" name="ahk" id="ahk" value="AHK"/>
                                                    <span className="c-checkbox__label">AHK</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="o-layout__item  u-1/6">
                                            <label className="c-checkbox">
                                                <div className="c-input">
                                                    <input className="c-checkbox__input  " type="checkbox" name="airsusp" id="airsusp" value="AIRSUSP"/>
                                                    <span className="c-checkbox__label">AIRSUSP</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="o-layout__item  u-1/6">
                                            <label className="c-checkbox">
                                                <div className="c-input">
                                                    <input className="c-checkbox__input  " type="checkbox" name="alu" id="alu" value="ALU"/>
                                                    <span className="c-checkbox__label">ALU</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="o-layout__item  u-1/6">
                                            <label className="c-checkbox">
                                                <div className="c-input">
                                                    <input className="c-checkbox__input  " type="checkbox" name="assi" id="assi" value="ASSI"/>
                                                    <span className="c-checkbox__label">ASSI</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="o-layout">
                                        <div className="o-layout__item  u-1/6">
                                            <label className="c-checkbox">
                                                <div className="c-input">
                                                    <input className="c-checkbox__input  " type="checkbox" name="aut" id="aut" value="AUT"/>
                                                    <span className="c-checkbox__label">AUT</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="o-layout__item  u-1/6">
                                            <label className="c-checkbox">
                                                <div className="c-input">
                                                    <input className="c-checkbox__input  " type="checkbox" name="awd" id="awd" value="AWD"/>
                                                    <span className="c-checkbox__label">AWD</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="o-layout__item  u-1/6">
                                            <label className="c-checkbox">
                                                <div className="c-input">
                                                    <input className="c-checkbox__input  " type="checkbox" name="business" id="business" value="BUSINESS"/>
                                                    <span className="c-checkbox__label">BUSINESS</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="o-layout__item  u-1/6">
                                            <label className="c-checkbox">
                                                <div className="c-input">
                                                    <input className="c-checkbox__input  " type="checkbox" name="def1" id="def1" value="DEF1"/>
                                                    <span className="c-checkbox__label">DEF1</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="o-layout__item  u-1/6">
                                            <label className="c-checkbox">
                                                <div className="c-input">
                                                    <input className="c-checkbox__input  " type="checkbox" name="def2" id="def2" value="DEF2"/>
                                                    <span className="c-checkbox__label">DEF2</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="o-layout__item  u-1/6">
                                            <label className="c-checkbox">
                                                <div className="c-input">
                                                    <input className="c-checkbox__input  " type="checkbox" name="def3" id="def3" value="DEF3"/>
                                                    <span className="c-checkbox__label">DEF3</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="o-layout">
                                        <div className="o-layout__item  u-1/6">
                                            <label className="c-checkbox">
                                                <div className="c-input">
                                                    <input className="c-checkbox__input  " type="checkbox" name="esd" id="esd" value="ESD"/>
                                                    <span className="c-checkbox__label">ESD</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="o-layout__item  u-1/6">
                                            <label className="c-checkbox">
                                                <div className="c-input">
                                                    <input className="c-checkbox__input  " type="checkbox" name="gra" id="gra" value="GRA"/>
                                                    <span className="c-checkbox__label">GRA</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="o-layout__item  u-1/6">
                                            <label className="c-checkbox">
                                                <div className="c-input">
                                                    <input className="c-checkbox__input  " type="checkbox" name="leder" id="leder" value="LEDER"/>
                                                    <span className="c-checkbox__label">LEDER</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="o-layout__item  u-1/6">
                                            <label className="c-checkbox">
                                                <div className="c-input">
                                                    <input className="c-checkbox__input  " type="checkbox" name="navi" id="navi" value="NAVI"/>
                                                    <span className="c-checkbox__label">NAVI</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="o-layout__item  u-1/6">
                                            <label className="c-checkbox">
                                                <div className="c-input">
                                                    <input className="c-checkbox__input  " type="checkbox" name="pdc" id="pdc" value="PDC"/>
                                                    <span className="c-checkbox__label">PDC</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="o-layout__item  u-1/6">
                                            <label className="c-checkbox">
                                                <div className="c-input">
                                                    <input className="c-checkbox__input  " type="checkbox" name="rearcam" id="rearcam" value="REARCAM"/>
                                                    <span className="c-checkbox__label">REARCAM</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="o-layout">
                                        <div className="o-layout__item  u-1/6">
                                            <label className="c-checkbox">
                                                <div className="c-input">
                                                    <input className="c-checkbox__input  " type="checkbox" name="rline" id="rline" value="RLINE"/>
                                                    <span className="c-checkbox__label">RLINE</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="o-layout__item  u-1/6">
                                            <label className="c-checkbox">
                                                <div className="c-input">
                                                    <input className="c-checkbox__input  " type="checkbox" name="shz" id="shz" value="SHZ"/>
                                                    <span className="c-checkbox__label">SHZ</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="o-layout__item  u-1/6">
                                            <label className="c-checkbox">
                                                <div className="c-input">
                                                    <input className="c-checkbox__input  " type="checkbox" name="sline" id="sline" value="SLINE"/>
                                                    <span className="c-checkbox__label">SLINE</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="o-layout__item  u-1/6">
                                            <label className="c-checkbox">
                                                <div className="c-input">
                                                    <input className="c-checkbox__input  " type="checkbox" name="sonder" id="sonder" value="SONDER"/>
                                                    <span className="c-checkbox__label">SONDER</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="o-layout__item  u-1/6">
                                            <label className="c-checkbox">
                                                <div className="c-input">
                                                    <input className="c-checkbox__input  " type="checkbox" name="sound" id="sound" value="SOUND"/>
                                                    <span className="c-checkbox__label">SOUND</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="o-layout__item  u-1/6">
                                            <label className="c-checkbox">
                                                <div className="c-input">
                                                    <input className="c-checkbox__input  " type="checkbox" name="stdhzg" id="stdhzg" value="STDHZG"/>
                                                    <span className="c-checkbox__label">STDHZG</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="o-layout">
                                        <div className="o-layout__item  u-1/6">
                                            <label className="c-checkbox">
                                                <div className="c-input">
                                                    <input className="c-checkbox__input  " type="checkbox" name="tel" id="tel" value="TEL"/>
                                                    <span className="c-checkbox__label">TEL</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="o-layout__item  u-1/6">
                                            <label className="c-checkbox">
                                                <div className="c-input">
                                                    <input className="c-checkbox__input  " type="checkbox" name="truckreg" id="truckreg" value="TRUCKREG"/>
                                                    <span className="c-checkbox__label">TRUCKREG</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="o-layout__item  u-1/6">
                                            <label className="c-checkbox">
                                                <div className="c-input">
                                                    <input className="c-checkbox__input  " type="checkbox" name="winter" id="winter" value="WINTER"/>
                                                    <span className="c-checkbox__label">WINTER</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="o-layout__item  u-1/6">
                                            <label className="c-checkbox">
                                                <div className="c-input">
                                                    <input className="c-checkbox__input  " type="checkbox" name="xenon" id="xenon" value="XENON"/>
                                                    <span className="c-checkbox__label">XENON</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="o-split">
                                        <div className="o-fieldset__row">
                                            <div className="o-layout">
                                                <div className="o-layout__item  u-1/1">
                                                    <button className="c-btn" type="submit"
                                                            disabled={this.state.isLoading}>
                                                        <span className="c-btn__text">Submit</span>
                                                        {this.state.isLoading ?
                                                            <div className="c-spinner c-spinner--small">
                                                                <svg>
                                                                    <circle className="c-spinner__indicator" cx="9"
                                                                            cy="9"
                                                                            r="8"
                                                                            fill="none"/>
                                                                </svg>

                                                            </div>
                                                            : null}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}
export default Audit