import React from 'react'
import axios from "axios";
import {Messages} from "primereact/messages";
import Select from 'react-select';
// import 'bootstrap/dist/css/bootstrap.min.css';
import {Link} from "react-router-dom";
import { getToken } from "../../utils";
// import React, { useState } from 'react';
// import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';



class Demo_uk extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            cap_code: 'AUA414SPO4SPTM  5',
            aktenid: 'a',
            nama: 50,
            kraftstoff: 'BENZIN',
            kw: '100',
            brand: 'Audi',
            first_register: '2020,1,1',
            cap_price: 15000,
            listenpreis_g: '15000',
            mileage: 50000,
            color: 'Gold',
            sales_channel: 'Auction',
            hdm: 'Yes',
            pr: '0',
        };
    }
    onSubmit = async (event) => {
        event.preventDefault();
        const pimp_icons_vals = ['pr', 'hds', 'auc', '_03', '_04', '_05', '_06', '_07', '_08','_09','_10','_11','_12','_13','_14','_15','_16','_17'
            ,'_18','_19','_20','_21','_22','_23','_24','_25','_26','_27'];

        this.setState({isLoading: true});

        let values = '';
        //let checkedBoxes = document.querySelectorAll('input[type=checkbox]:checked');
        //for(let i = 0; i<checkedBoxes.length;i++){
        //  values = values + checkedBoxes[i].value + ';'
        //}
        for(let i = 0; i < pimp_icons_vals.length ;i++){
            let checkbox = document.getElementById(pimp_icons_vals[i]);
            if (checkbox.checked)
                values = values + checkbox.value + ';'
        }
        let checkbox_pr = document.getElementById('pr');
        if (checkbox_pr.checked)
            this.state.pr = 'Yes';
        else
            this.state.pr = 'No';

        let checkbox_hds = document.getElementById('hds');
        if (checkbox_hds.checked)
            this.state.hds = 'High Demand Sale';
        else
            this.state.hds = '0';


        // axios call
        const params = {
            "cap_code": this.state.cap_code,
            "brand": this.state.brand,  // BMW, Audi, SEAT, SKODA
            "sales_channel": this.state.sales_channel,  // "Auction",
            "high_demand_sale": this.state.hds,  // "0",
            "panoramic_roof": this.state.pr,  // "Yes",
            "nama_points": Number(this.state.nama),
            "erstzulassung": this.state.first_register,
            "fahrleistung": Number(this.state.mileage),
            "cap_price": this.state.cap_price,
            "farbe_finish": this.state.color
        };

        try {
            const token = await getToken();
            const axiosConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'x-service-id': process.env.REACT_APP_X_SERVICE_ID
                }
            };
            axios.post(process.env.REACT_APP_BE_URL, params, axiosConfig)
                .then(result => {
                    // array containing the models and details
                    // this.setState({result: JSON.parse(result.data)});
                    this.setState({result: result.data});
                    console.log(result.data);
                    this.setState({isLoading: false});
                })
                .catch(err => {
                    console.log(err);
                    this.messages.show({ severity: 'error', summary: 'Error', detail: err.message });
                    this.setState({isLoading: false});
                });
        } catch (e) {
            this.messages.show({ severity: 'error', summary: 'Error', detail: e.message });
        }
    };



    render() {
        const sales_channel_options = [
            { label: "Auction", value: 1 },
            { label: "Trade-Online", value: 2 },
        ];
        const brand_options = [
            { label: "ALFA ROMEO", value: 1 },
            { label: "Audi", value: 2 },
            { label: "BENTLEY", value: 3 },
            { label: "BMW", value: 4 },
            { label: "CHEVROLET", value: 5 },
            { label: "CHRYSLER", value: 6 },
            { label: "Citroen", value: 7 },
            { label: "DACIA", value: 8 },
            { label: "DS", value: 9 },
            { label: "FITA", value: 10 },
            { label: "FORD", value: 11 },
            { label: "HONDA", value: 12 },
            { label: "HYUNDAI", value: 13 },
            { label: "INFINITI", value: 14 },
            { label: "JAGUAR", value: 15 },
            { label: "KIA", value: 16 },
            { label: "LAMBORGHINI", value: 17 },
            { label: "LAND ROVER", value: 18 },
            { label: "LCV CITROEN", value: 19 },
            { label: "LCV FIAT", value: 20 },
            { label: "LCV FORD", value: 21 },
            { label: "LCV MERCEDES-BENZ", value: 22 },
            { label: "LCV MITSUBISHI", value: 23 },
            { label: "LCV NISSAN", value: 24 },
            { label: "LCV PEUGEOT", value: 25 },
            { label: "LCV RENAULT", value: 26 },
            { label: "LCV TOYOTA", value: 27 },
            { label: "LCV VAUXHALL", value: 28 },
            { label: "LCV VOLKSWAGEN", value: 29 },
            { label: "LEXUS", value: 30 },
            { label: "MASERATI", value: 31 },
            { label: "MAZDA", value: 32 },
            { label: "MERCEDES-BENZ", value: 33 },
            { label: "MINI", value: 34 },
            { label: "MITSUBISHI", value: 35 },
            { label: "McLaren", value: 36 },
            { label: "NISSAN", value: 37 },
            { label: "PEUGEOT", value: 38 },
            { label: "PORSCHE", value: 39 },
            { label: "RENAULT", value: 40 },
            { label: "SEAT", value: 41 },
            { label: "SKODA", value: 42 },
            { label: "SMART", value: 43 },
            { label: "SUZUKI", value: 44 },
            { label: "TESLA", value: 45 },
            { label: "TOYOTA", value: 46 },
            { label: "VAUXHALL", value: 47 },
            { label: "VOLKSWAGEN", value: 48 },
            { label: "VOLVO", value: 49 },
        ];
        const colour_options = [
            { label: "Beige", value: 1 },
            { label: "Black", value: 2 },
            { label: "Blue", value: 3 },
            { label: "Brown", value: 4 },
            { label: "Gold", value: 5 },
            { label: "Green", value: 6 },
            { label: "Grey", value: 7 },
            { label: "Orange", value: 8 },
            { label: "Premium", value: 9 },
            { label: "Purple", value: 10 },
            { label: "Red", value: 11 },
            { label: "Silver", value: 12 },
            { label: "Standard", value: 13 },
            { label: "Undesirable", value: 14 },
            { label: "White", value: 15 },
            { label: "Yellow", value: 16 },
        ];


        return (
            <div>
                <main className="o-page-wrap  o-page-wrap--small">
                    <div className="o-page-wrap u-mb-small">
                        <div className="o-split o-split--top" >
                            <span></span>
                         <span style={{textAlign: "left", align:"left"}}>
                                            <article className="c-card c-card--large c-card--border" style={{textAlign: "left",  align:"left"}}>
                                                <p style={{color:'red', lineHeight: 0.1}}><small>Algorithm trained: 12.03.2020</small></p>
                                                <p style={{color:'red', lineHeight: 0.1}}><small>Training data: 01.09.2017 - 31.08.2019</small></p>
                                                <p style={{color:'red', lineHeight: 0.1}}><small>Amount of Training data: 137.021</small></p>
                                                <p style={{color:'red', lineHeight: 0.1}}><small>Last updated: 20.04.2020</small></p>
                                            </article>
                                        </span>
                        </div>
                    </div>
                    <div className="o-page-wrap u-mb-large">
                        <header className="o-even-slimmer-page-wrap  u-text-center">
                            <h1>PROTOTYPE</h1>
                            <p>Please insert the following values to get a price prediction:</p>
                            <br/>
                            <Messages ref={(el) => this.messages = el}></Messages>
                            <div id="login-form-banking"
                                 className="c-tab-content-container js-tab-container__content u-mb-none u-pb-none">
                                <form onSubmit={this.onSubmit}>
                                    <fieldset className="o-fieldset u-mb" style={{textAlign: "left"}}>
                                        <div className="o-split o-split--top" >
                                    <span>
                                        <h2>Parameter</h2>
                                      <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="cap_code">CAP Code</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="cap_code"
                                                           name="cap_code" value={this.state.cap_code}
                                                           onChange={(e) => {
                                                               this.setState({cap_code: e.target.value})
                                                           }}/>
                                                </div>
                                            </div>
                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="cap_price">CAP Price</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="cap_price"
                                                           name="cap_price" value={this.state.cap_price}
                                                           onChange={(e) => {
                                                               this.setState({cap_price: e.target.value})
                                                           }} required/>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="nama">NAMA Points</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="nama"
                                                           name="nama" value={this.state.nama}
                                                           onChange={(e) => {
                                                               this.setState({nama: e.target.value})
                                                           }}/>
                                                </div>
                                            </div>
                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="sales_channel">Sales Channel</label>

                                                   <Select className="custom-class" type="text" id="sales_channel"
                                                           name="sales_channel" value={sales_channel_options.label}
                                                           onChange={(e) => {
                                                               this.setState({sales_channel: e.label})
                                                           }} required
                                                           options={ sales_channel_options } />

                                            </div>
                                            </div>
                                        </div>
                                        <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="brand">Brand</label>
                                                        <Select className="custom-class" type="text" id="brand"
                                                                name="brand" value={brand_options.label}
                                                                onChange={(e) => {
                                                                    this.setState({brand: e.label})
                                                                }} required
                                                                options={ brand_options } />
                                            </div>
                                             <div className="o-layout__item  u-1/2">
                                                <label htmlFor="color">Colour</label>
                                                    <Select className="custom-class" type="text" id="color"
                                                            name="color" value={colour_options.label}
                                                            onChange={(e) => {
                                                                this.setState({color: e.label})
                                                            }} required
                                                            options={ colour_options } />
                                            </div>
                                        </div>
                                        </div>
                                        <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="first_register">First Register</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="first_register"
                                                           name="first_register" value={this.state.first_register}
                                                           onChange={(e) => {
                                                               this.setState({first_register: e.target.value})
                                                           }} required/>
                                                </div>
                                            </div>
                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="mileage">Mileage</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="mileage"
                                                           name="mileage" value={this.state.mileage}
                                                           onChange={(e) => {
                                                               this.setState({mileage: e.target.value})
                                                           }} required/>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </span>
                                            <span style={{textAlign: "left", align:"left"}}>
                                            <h2>Result</h2>
                                            <article className="c-card c-card--large c-card--border" style={{textAlign: "left",  align:"left"}}>
                                                {this.state.result ?
                                                    <div className="c-card__body">
                                                        <p>B2B-Prediction: {this.state.result.Prediction} <span>£</span></p>
                                                        <p>B2C-Prediction: {this.state.result.Prediction_B2C} <span>£</span></p>
                                                        <p>Score: {this.state.result.Score}</p>
                                                    </div>
                                                    : null}
                                            </article>
                                        </span>
                                        </div>
                                        <br/>
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="pr" id="pr" value="PR"/>
                                                        <span className="c-checkbox__label">Panoramic Roof</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="hds" id="hds" value="HDS"/>
                                                        <span className="c-checkbox__label">High Demand Sale</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="auc" id="auc" value="AUC"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="_03" id="_03" value="_"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="_04" id="_04" value="_"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="_05" id="_05" value="_"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="_06" id="_06" value="_"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="_07" id="_07" value="_"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="_08" id="_08" value="_"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="_09" id="_09" value="_"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="_10" id="_10" value="_"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="_11" id="_11" value="_"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="_12" id="_12" value="_"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="_13" id="_13" value="_"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="_14" id="_14" value="_"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="_15" id="_15" value="_"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="_16" id="_16" value="_"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="_17" id="_17" value="_"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="_18" id="_18" value="_"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="_19" id="_19" value="_"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="_20" id="_20" value="_"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="_21" id="_21" value="_"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="_22" id="_22" value="_"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="_23" id="_23" value="_"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="_24" id="_24" value="_"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="_25" id="_25" value="_"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="_26" id="_26" value="_"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="_27" id="_27" value="_"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="o-split">
                                            <div className="o-fieldset__row">
                                                <div className="o-layout">
                                                    <div className="o-layout__item  u-1/1">
                                                        <button className="c-btn" type="submit"
                                                                disabled={this.state.isLoading}>
                                                            <span className="c-btn__text">Submit</span>
                                                            {this.state.isLoading ?
                                                                <div className="c-spinner c-spinner--small">
                                                                    <svg>
                                                                        <circle className="c-spinner__indicator" cx="9"
                                                                                cy="9"
                                                                                r="8"
                                                                                fill="none"/>
                                                                    </svg>
                                                                </div>
                                                                : null}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </header>
                    </div>
                </main>
            </div>
        );
    }
}
export default Demo_uk











