import React from 'react'
import {Messages} from 'primereact/messages';
import { Auth } from "aws-amplify";
import {connect} from "react-redux";

class ChangePassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            newPassword: '',
            newPasswordConfirm: '',
            isLoading: false
        };
    }

    onSubmit = (event) => {
        event.preventDefault();

        if (this.state.newPassword !== this.state.newPasswordConfirm) {
            this.messages.show({severity: 'error', summary: 'Password Error', detail: 'Passwords do not match.'})
        } else {
            this.setState({isLoading: true});
            Auth.completeNewPassword(
                this.props.user,
                this.state.newPassword,
                null //required user attributes
            ).then(user => {
                this.props.onSignIn(user);
                this.props.history.push('/');
            }).catch(err => {
                this.messages.show({ severity: 'error', summary: 'Login Failure', detail: err.message });
                this.setState({isLoading: false});
            });
        }
    };


    render() {
        return (
            <main className="o-page-wrap  o-page-wrap--small">
                <div className="o-page-wrap u-mb-large">
                    <header className="o-even-slimmer-page-wrap  u-text-center">
                        <h1>Change password</h1>
                        <p>Please change your password. The new password must be 8 characters long and contain
                            lowercase and uppercase letters, numbers and a special symbol.</p>

                        <Messages ref={(el) => this.messages = el}></Messages>

                        <div id="login-form-banking"
                             className="c-tab-content-container js-tab-container__content u-mb-none u-pb-none">
                            <form onSubmit={this.onSubmit}>
                                <fieldset className="o-fieldset u-mb">
                                    <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/1">
                                                <label htmlFor="username">Username or E-Mail</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="username" name="username"
                                                           value={this.state.username}
                                                           onChange={(e) => {
                                                               this.setState({username: e.target.value})
                                                           }} required/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/1">
                                                <label htmlFor="password">Current Password</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="password" id="password" name="password"
                                                           value={this.state.password}
                                                           onChange={(e) => {
                                                               this.setState({password: e.target.value})
                                                           }} required/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/1">
                                                <label htmlFor="newpw">New Password</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="password" id="newpw" name="newpw"
                                                           value={this.state.newPassword}
                                                           onChange={(e) => {
                                                               this.setState({newPassword: e.target.value})
                                                           }} required/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/1">
                                                <label htmlFor="confirmPassword">Confirm New Password</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="password" id="confirmPassword"
                                                           name="confirmPassword" value={this.state.newPasswordConfirm}
                                                           onChange={(e) => {
                                                               this.setState({newPasswordConfirm: e.target.value})
                                                           }} required/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>

                                <div className="o-split">
                                    <button className="c-btn" type="submit" disabled={this.state.isLoading}>
                                        <span className="c-btn__text">Submit</span>
                                        {this.state.isLoading ?
                                            <div className="c-spinner c-spinner--small">
                                                <svg>
                                                    <circle className="c-spinner__indicator" cx="9" cy="9"
                                                            r="8"
                                                            fill="none"/>
                                                </svg>

                                            </div>
                                            : null}
                                    </button>
                                </div>
                            </form>

                        </div>
                    </header>
                </div>
            </main>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
};

export default connect(mapStateToProps)(ChangePassword);