import React from 'react'
import axios from "axios";

import {Messages} from "primereact/messages";
import Select from 'react-select';
import { useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import {Link} from "react-router-dom";
import { getToken } from "../../utils";
// import React, { useState } from 'react';
// import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';



class Demo_b2c_es extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            vin: 'VIN', //TMBEB6NJ5KZ078731
            registration: '01.01.1950',
            mileage: 60000,
            damage: 500,
            list_price: 32000,
            uniqueId: "frontend-request",
            text_area: "VIN\t01.01.1950\t60000\t500\t32000",
            single: true,
            result_batch: {"marketValueAmount": [], "score": [], "vin": []},
            result_batch_score: [],
        };
    }

    parseLine(line, index) {
        // returns null if error during parsing
        const newInput = {
            // inputNumber:index,
            vin:'VIN',
            initialRegistrationDate:'01.01.1950',
            odometer:60000,
            damagesTotal:500,
            msrpTotal:32000,
            "countryCode": "ES",
            "b2b": "B2C",
            "msrpUnit": "EUR",
            "damageUnit": "EUR",
            "odometerUnit": "km",
            "uniqueId": "frontend-request"
        };

        // use a fancy state machine like in text mining to match input
        const data = line.split(/\t/);
        const nrOfElements = data.length;
        if (nrOfElements === 0) {
            return null;
        }
        let number_re = /\d+/;
        let vin_re = /[a-zA-Z\d]{17}/;
        let float_re = /^-?\d+(.\d*)?/; // /\d+(,\d*)?/;
        let german_number_re = /^-?\d{1,3}(?:\.\d{3})*(?:,\d+)?$/;
        let date_re_short = /\d+\.\d+\.\d\d/;
        let date_re_hypen_short = /\d+-\d+-\d\d/;
        let date_re_slash_short = /\d+\/\d+\/\d\d/;
        let date_re_long = /\d+\.\d+\.\d\d\d\d/;
        let date_re_hyphen_long = /\d+-\d+-\d\d\d\d/;
        let date_re_slash_long = /\d+\/\d+\/\d\d\d\d/;
        let elementIndex = 0;
        let element = data[elementIndex];

        newInput.vin = element;
        elementIndex++;
        element = data[elementIndex];

        // mandatory registration date
        if (this.matchExact(date_re_long, element)) {
            newInput.initialRegistrationDate = element;
        }else if (this.matchExact(date_re_hyphen_long, element)) {
            newInput.initialRegistrationDate = this.format4DigitDate(element, '-', true);
        }else if (this.matchExact(date_re_slash_long, element)) {
            newInput.initialRegistrationDate = this.format4DigitDate(element, '/', true);
        }else {
            return null;
        }
        elementIndex++;
        element = data[elementIndex];

        // optional odometer, if not found skip
        if (this.matchExact(german_number_re, element) || this.matchExact(number_re, element) ||this.matchExact(float_re, element)) {
            newInput.odometer = Math.trunc(parseFloat(element.replace('.', '').replace(',','.')));
        }
        elementIndex++;
        element = data[elementIndex];

        // optional damages total, if not found skip
        if (this.matchExact(german_number_re, element) || this.matchExact(number_re, element) ||this.matchExact(float_re, element)) {
            newInput.damagesTotal = Math.trunc(parseFloat(element.replace('.', '').replace(',','.')));
        }
        elementIndex++;
        element = data[elementIndex];

        // optional msrp total, if not found skip
        if (this.matchExact(german_number_re, element) || this.matchExact(number_re, element) ||this.matchExact(float_re, element)) {
            newInput.msrpTotal = Math.trunc(parseFloat(element.replace('.','').replace(',','.')));
        }

        newInput.uniqueId = "frontend-request-" + new Date().toLocaleString().replace(', ', '-') + "-" + crypto.randomUUID()

        elementIndex++;
        element = data[elementIndex];
        // optional surcharge S1
        /*
        if (this.matchExact(float_re, element)) {
            newInput.surcharge_s1 = parseFloat(element);
            this.state.batch_surcharge_s1.push(parseFloat(element))
        }
        elementIndex++;
        element = data[elementIndex];
        // optional surcharge S2
        if (this.matchExact(float_re, element)) {
            newInput.surcharge_s2 = parseFloat(element);
            this.state.batch_surcharge_s2.push(parseFloat(element))
        }

        */
        return newInput;
    }

    convertTo4Digit = (date, separator, dayfirst) => {
        // convert everything starting from 50 to 19xx
        const parts = date.split(separator);
        const year = parseInt(parts[2]);
        if (year > 50) {
            if (dayfirst === true) {
                return parts[0] + '.' + parts[1] + '.19' + parts[2];
            }else {
                return parts[1] + '.' + parts[0] + '.19' + parts[2];
            }
        }else {
            if (dayfirst === true) {
                return parts[0] + '.' + parts[1] + '.20' + parts[2];
            }else {
                return parts[1] + '.' + parts[0] + '.20' + parts[2];
            }
        }
        // the rest is converted to 20xx
    };

    format4DigitDate = (date, separator, dayfirst) => {
        // convert everything starting from 50 to 19xx
        const parts = date.split(separator);
        if(dayfirst === true) {
            return parts[0] + '.' + parts[1] + '.' + parts[2];
        }else {
            return parts[1] + '.' + parts[0] + '.' + parts[2];
        }
    };

    matchExact(r, str) {
        let match = str.match(r);
        return match && str === match[0];
    }

    onSubmit = async (event) => {
        event.preventDefault()

        this.setState({isLoading: true});

        const params_single = {
            "countryCode": "ES",
            "b2b": "B2C",
            "vin": this.state.vin, //"TMBEB6NJ5KZ078731",
            "msrpTotal": Math.trunc(Number(this.state.list_price.toString().replace('.', '').replace(',', '.'))), //"287600",
            "msrpUnit": "EUR",
            "initialRegistrationDate": this.state.registration, //"04.02.19",
            "odometer": Math.trunc(Number(this.state.mileage.toString().replace('.', '').replace(',', '.'))), //"15539",
            "damagesTotal": Math.trunc(Number(this.state.damage.toString().replace('.', '').replace(',', '.'))), //"8254",
            "damageUnit": "EUR",
            "odometerUnit": "km",
            "uniqueId": "frontend-request-" + new Date().toLocaleString().replace(', ', '-') + "-" + crypto.randomUUID()
        }

        const lines = this.state.text_area.split('\n');
        let newEntries = [];
        for (let i = 0; i < lines.length; i++) {
            let line = lines[i];
            if (line.trim() === '') {
                // ignore empty lines
                continue;
            }
            const newEntry = this.parseLine(line, i);
            if (newEntry) {
                newEntries.push(newEntry);
            }
        }
        try {
            const token = await getToken();
            const axiosConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'x-service-id': process.env.REACT_APP_X_SERVICE_ID
                }
            };

            if (this.state.single) {
                axios.post(process.env.REACT_APP_BE_URL, params_single, axiosConfig)
                    .then(result => {
                        // array containing the models and details
                        // this.setState({result: JSON.parse(result.data)});
                        this.setState({result: result.data});
                        console.log(result.data);
                        this.setState({isLoading: false});
                    })
                    .catch(err => {
                        console.log(err);
                        this.messages.show({severity: 'error', summary: 'Error', detail: err.message});
                        this.setState({result: { marketValueAmount: 0}});  // clear state in case of error
                        this.setState({isLoading: false});
                    });
            } else {
                var success = 1;
                this.setState({result_batch: {"vin": [], "marketValueAmount": [], "score": []}});
                // this.setState({result_batch_score: []});
                while (newEntries.length > 0) {
                    const slicedArray = newEntries.splice(0, 1);
                    await axios.post(process.env.REACT_APP_BE_URL, slicedArray[0], axiosConfig)
                        .then(result => {
                            // array containing the models and details
                            // this.setState({result: JSON.parse(result.data)});
                            this.setState({
                                result_batch: {
                                    "vin": this.state.result_batch.vin.concat(slicedArray[0]["vin"]),
                                    "marketValueAmount": this.state.result_batch.marketValueAmount.concat(result.data.marketValueAmount),
                                    "score": this.state.result_batch.score.concat(result.data.score)
                                }});
                            //this.setState({result_batch: this.state.result_batch.prediction_brutto_rounded.concat(result.data.result.prediction_brutto_rounded)});
                            //this.setState({result_batch: this.state.result_batch.prediction_netto.concat(result.data.result.prediction_netto)});
                            //this.setState({result_batch_pred_brutto_rounded: this.state.result_batch_prediction_brutto_rounded.concat(result.data.prediction_brutto_rounded)});
                            //this.setState({result_batch_prediction_netto: this.state.result_batch_prediction_netto.concat(result.data.prediction_netto)});
                            // this.setState({result_batch_score: this.state.result_batch_score.concat(result.data.score)});
                            console.log(result.data);
                        })
                        .catch(err => {
                            success = 0
                            console.log(err);
                            this.messages.show({severity: 'error', summary: 'Error', detail: err.message});
                            this.setState({
                                result_batch: {
                                    "vin": this.state.result_batch.vin.concat(slicedArray[0]["vin"]),
                                    "marketValueAmount": this.state.result_batch.marketValueAmount.concat(0),
                                    "score": this.state.result_batch.score.concat(0)
                                }});
                        });
                }
                if (success === 0) {
                    this.setState({result_batch_pred: ['Error']});
                    this.setState({result_batch_score: ['Error']});
                }
                this.setState({isLoading: false});
            }
        } catch (e) {
            this.messages.show({severity: 'error', summary: 'Error', detail: e.message});
        }
    };


    mapArrays(array1, array2, array3) {
        // helper function that zips three arrays
        // call as: zip(["a","b","c"], [1,2,3], ["d", "e", "f"])
        // returns: ["a", 1, "d"], ["b", 2, "e"], ["c", 3, "f"]

        if (array1.length !== array2.length || array1.length !== array3.length) {
            throw new Error("Arrays must have the same length");
        }

        const result = [];

        for (let i = 0; i < array1.length; i++) {
            result.push([array1[i], array2[i], array3[i]]);
        }

        return result;
    }

    GetResults_B2C() {
        // returns the entries from result_batch formatted as string in the following order, each result in new line:
        // vin   marketValueAmount   (1/1.21)*marketValueAmount   score

        const res1 = this.state.result_batch.marketValueAmount;
        const res2 = this.state.result_batch.score;
        const res3 = this.state.result_batch.vin;

        let resGes = this.mapArrays(res1, res2, res3);

        return (resGes).map((res, pos) => resGes[pos][2] + '\t' + resGes[pos][0].toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '\t' + ((1/1.21)*resGes[pos][0]).toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '\t' + (resGes[pos][1]).toLocaleString('de-DE', {minimumFractionDigits: 0, maximumFractionDigits: 0}) + '\n').join('');
    }

    getBatchScore(score){
        if (score && score > 24) {
            return "GREEN"
        } else if (score && score > 9) {
            return "YELLOW";
        } else {
            return "RED"
        }
    }

    getScore() {
        const score = this.state.result.score;
        if (score && score > 24) {
            return "GREEN"
        } else if (score && score > 9) {
            return "YELLOW";
        } else {
            return "RED"
        }
    }

    render() {
        return (
            <div>
                <main className="o-page-wrap  o-page-wrap--small">
                    <div className="o-page-wrap u-mb-small">
                        <div className="o-split o-split--top" >
                            <span></span>
                            <span style={{textAlign: "left", align:"left"}}>
                                            <article className="c-card c-card--large c-card--border" style={{textAlign: "left",  align:"left"}}>
                                                <p style={{color:'red', lineHeight: 0.1}}><small>Algorithm trained: CatBoost</small></p>
                                                <p style={{color:'red', lineHeight: 0.1}}><small>Training data: LOT data</small></p>
                                                <p style={{color:'red', lineHeight: 0.1}}><small>Amount of Training data: 386.339</small></p>
                                                <p style={{color:'red', lineHeight: 0.1}}><small>Model updates: every Friday</small></p>
                                            </article>
                                        </span>
                        </div>
                    </div>
                    <div className="o-page-wrap u-mb-large">
                        <header className="o-even-slimmer-page-wrap  u-text-center">
                            <h1>PROTOTYPE B2C ES v1.0</h1>
                            <p>Please enter the following parameters to get a AI price prediction:</p>
                            <br/>
                            <Messages ref={(el) => this.messages = el}></Messages>
                            <div id="login-form-banking"
                                 className="c-tab-content-container js-tab-container__content u-mb-none u-pb-none">
                                <form onSubmit={this.onSubmit}>
                                    <fieldset className="o-fieldset u-mb" style={{textAlign: "left"}}>
                                        <div className="o-split o-split--top" >
                                    <span>
                                        <h2>Single vehicle</h2>
                                        <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="nama">VIN</label>
                                                <div className="c-input  ">
                                                </div>
                                            </div>
                                            <div className="o-layout__item  u-1/2">
                                                <input className="c-input__input" type="text" id="vin"
                                                       name="vin" value={this.state.vin}
                                                       onChange={(e) => {
                                                           this.setState({vin: e.target.value})
                                                       }}/>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="nama">First registration date [MM.DD.YYYY]</label>
                                                <div className="c-input  ">
                                                </div>
                                            </div>
                                            <div className="o-layout__item  u-1/2">
                                                <input className="c-input__input" type="text" id="registration"
                                                       name="registration" value={this.state.registration}
                                                       onChange={(e) => {
                                                           this.setState({registration: e.target.value})
                                                       }}/>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="nama">Mileage [20.000 km]</label>
                                                <div className="c-input  ">
                                                </div>
                                            </div>
                                            <div className="o-layout__item  u-1/2">
                                                <input className="c-input__input" type="text" id="mileage"
                                                       name="mileage" value={this.state.mileage}
                                                       onChange={(e) => {
                                                           this.setState({mileage: e.target.value})
                                                       }}/>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="nama">Damages [1.234,56 €]</label>
                                                <div className="c-input  ">
                                                </div>
                                            </div>
                                            <div className="o-layout__item  u-1/2">
                                                <input className="c-input__input" type="text" id="damage"
                                                       name="damage" value={this.state.damage}
                                                       onChange={(e) => {
                                                           this.setState({damage: e.target.value})
                                                       }}/>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="nama">List price [12.345,67 €]</label>
                                                <div className="c-input  ">
                                                </div>
                                            </div>
                                            <div className="o-layout__item  u-1/2">
                                                <input className="c-input__input" type="text" id="list_price"
                                                       name="list_price" value={this.state.list_price}
                                                       onChange={(e) => {
                                                           this.setState({list_price: e.target.value})
                                                       }}/>
                                            </div>
                                            </div>
                                        </div>

                                    </span>
                                            <span style={{textAlign: "left", align:"left"}}>
                                            <h2>Result</h2>
                                            <article className="c-card c-card--large c-card--border" style={{textAlign: "left",  align:"left"}}>
                                                {this.state.result ?
                                                    <div className="c-card__body">
                                                        <p><b>Gross price (incl. 21% VAT):</b> {this.state.result.marketValueAmount.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})} <span>€</span></p>
                                                        <p><b>Net price (without 21% VAT):</b>&nbsp;  {((1/1.21) *this.state.result.marketValueAmount).toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})} <span>€</span></p>
                                                        <p><b>Score:</b>&nbsp;  {(this.state.result.score).toLocaleString('de-DE', {minimumFractionDigits: 0, maximumFractionDigits: 0})}</p>
                                                    </div>
                                                    : null}
                                            </article>
                                        </span>
                                        </div>
                                        <br/>
                                        <br/>
                                        <div className="o-split">
                                            <div className="o-fieldset__row">
                                                <div className="o-layout">
                                                    <div className="o-layout__item  u-1/1">
                                                        <button className="c-btn" type="submit" onClick={() => this.setState({single: true})}
                                                                disabled={this.state.isLoading}>
                                                            <span className="c-btn__text">Submit</span>
                                                            {this.state.isLoading ?
                                                                <div className="c-spinner c-spinner--small">
                                                                    <svg>
                                                                        <circle className="c-spinner__indicator" cx="9"
                                                                                cy="9"
                                                                                r="8"
                                                                                fill="none"/>
                                                                    </svg>
                                                                </div>
                                                                : null}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <span style={{textAlign: "center", align:"center"}}>
                                                <h2>Batch</h2>
                                                <label htmlFor="mileage">VIN / First registration date / Mileage / Damages / List price</label>
                                                    <textarea className="c-input__input" type="textarea" id="text_area"
                                                              style={{resize: 'vertical', width:1050, height:150}}
                                                              name="text_area" value={this.state.text_area}
                                                              onChange={(e) => {
                                                                  this.setState({text_area: e.target.value})
                                                              }} required/>
                                            </span>
                                            <span style={{textAlign: "center", align:"center"}}>
                                                    <h2>Results</h2>
                                                </span>
                                            <div className="o-split">
                                                <div className="o-layout__item  u-1/1">
                                                    <label htmlFor="b2b">VIN / Gross price / Net price / Score </label>
                                                    <textarea id="noter-text-area" name="textarea"
                                                              style={{resize: 'vertical', width:1000, height:150}}
                                                              value={this.GetResults_B2C()}/>
                                                </div>
                                            </div>
                                        </div>
                                        <br/>
                                        <br/>
                                        <div className="o-split">
                                            <div className="o-fieldset__row">
                                                <div className="o-layout">
                                                    <div className="o-layout__item  u-1/1">
                                                        <button className="c-btn" type="submit" onClick={() => this.setState({single: false})}
                                                                disabled={this.state.isLoading}>
                                                            <span className="c-btn__text">Submit</span>
                                                            {this.state.isLoading ?
                                                                <div className="c-spinner c-spinner--small">
                                                                    <svg>
                                                                        <circle className="c-spinner__indicator" cx="9"
                                                                                cy="9"
                                                                                r="8"
                                                                                fill="none"/>
                                                                    </svg>
                                                                </div>
                                                                : null}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </header>
                    </div>
                </main>
            </div>
        );
    }
}
export default Demo_b2c_es











