import React from 'react';

const UploadedFile = (props) => (

    <div className="c-upload-item  is-success">
        <i className="c-upload-item__icon  c-icon  c-icon--[semantic-file]" data-filetype="xls" aria-hidden="true" role="img"> </i>

        <div className="c-upload-item__content">

            <div className="c-upload-item__title-row">
                <p className="c-upload-item__title">{props.file.filename}</p>
                <div className="c-upload-item__interaction-icons">
                    <button className="c-upload-item__interaction-icon  c-upload-item__interaction-icon--main"></button>
                    <button className="c-upload-item__interaction-icon  c-upload-item__interaction-icon--secondary"></button>
                    <i className="c-upload-item__status-icon" aria-hidden="true" role="img"></i>
                </div>
            </div>

            <div className="c-upload-item__progress">
                <progress className="c-progress  is-success" value="100" max="100"></progress>

            </div>

            <p className="c-upload-item__subtitle-row">
                <span className="c-upload-item__filesize-progress">-</span>
                <span className="c-upload-item__filesize"></span>
                <span className="c-upload-item__filesize-progress"> (100% uploaded)</span>
                <span className="c-upload-item__message">
                    Upload successful.
                </span>
            </p>

        </div>

    </div>

);
export default UploadedFile;
