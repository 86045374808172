import React from 'react'
import axios from "axios";

import {Messages} from "primereact/messages";
import Select from 'react-select';
import { useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import {Link} from "react-router-dom";
import { getToken } from "../../utils";
// import React, { useState } from 'react';
// import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';



class Demo_cz extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            vin: 'VIN', //TMBEB6NJ5KZ078731
            registration: '01.01.1950',
            mileage: 60000,
            damage: 500,
            list_price: 32000,
            surcharge_s1: 0.02,
            surcharge_s2: 0.04,
            text_area: "VIN\t01.01.1950\t60000\t500\t32000\t0.02\t0.04",
            single: true,
            result_batch: {"B2B":[0.0], "B2C":[1.0], "SCORE":["RED"]},
            result_batch_pred: [],
            result_batch_score: [],
            batch_surcharge_s1: [],
            batch_surcharge_s2: [],
        };
    }

    parseLine(line, index) {
        // returns null if error during parsing
        const newInput = {
            // inputNumber:index,
            vin:'VIN',
            initialRegistrationDate:'01.01.1950',
            odometer:60000,
            damagesTotal:500,
            msrpTotal:32000,
            // surcharge_s1:0.02,
            // surcharge_s2:0.04,
            "countryCode": "CZ",
            "b2b": "B2B",
            "msrpUnit": "CZK",
            "damageUnit": "CZK",
            "odometerUnit": "km",
            "offerType": "auction",
            "salesDate": "",
            "salesChannel": "B2B",
            "contractType": "NEW",
            "uniqueId": "",
            "dummy1": "",
            "dummy2": "",
            "dummy3": ""
        };

        // use a fancy state machine like in text mining to match input
        const data = line.split(/\t/);
        const nrOfElements = data.length;
        if (nrOfElements === 0) {
            return null;
        }
        let number_re = /\d+/;
        let vin_re = /[a-zA-Z\d]{17}/;
        let float_re = /^-?\d+(.\d*)?/; // /\d+(,\d*)?/;
        let date_re_short = /\d+\.\d+\.\d\d/;
        let date_re_hypen_short = /\d+-\d+-\d\d/;
        let date_re_slash_short = /\d+\/\d+\/\d\d/;
        let date_re_long = /\d+\.\d+\.\d\d\d\d/;
        let date_re_hyphen_long = /\d+-\d+-\d\d\d\d/;
        let date_re_slash_long = /\d+\/\d+\/\d\d\d\d/;
        let elementIndex = 0;
        let element = data[elementIndex];

        newInput.vin = element;
        elementIndex++;
        element = data[elementIndex];

        // mandatory registration date
        if (this.matchExact(date_re_long, element)) {
            newInput.initialRegistrationDate = element;
        }else if (this.matchExact(date_re_hyphen_long, element)) {
            newInput.initialRegistrationDate = this.format4DigitDate(element, '-', true);
        }else if (this.matchExact(date_re_slash_long, element)) {
            newInput.initialRegistrationDate = this.format4DigitDate(element, '/', true);
        }else {
            return null;
        }
        elementIndex++;
        element = data[elementIndex];

        // optional mileage, if not found skip
        if (this.matchExact(number_re, element)) {
            newInput.odometer = parseInt(element);
        }
        elementIndex++;
        element = data[elementIndex];

        // optional mileage, if not found skip
        if (this.matchExact(number_re, element)) {
            newInput.damagesTotal = parseInt(element);
        }
        elementIndex++;
        element = data[elementIndex];

        // optional mileage, if not found skip
        if (this.matchExact(number_re, element)) {
            newInput.msrpTotal = parseInt(element);
        }
        elementIndex++;
        element = data[elementIndex];
        // optional surcharge S1
        if (this.matchExact(float_re, element)) {
            newInput.surcharge_s1 = parseFloat(element);
            this.state.batch_surcharge_s1.push(parseFloat(element))
        }
        elementIndex++;
        element = data[elementIndex];
        // optional surcharge S2
        if (this.matchExact(float_re, element)) {
            newInput.surcharge_s2 = parseFloat(element);
            this.state.batch_surcharge_s2.push(parseFloat(element))
        }
        return newInput;
    }

    convertTo4Digit = (date, separator, dayfirst) => {
        // convert everything starting from 50 to 19xx
        const parts = date.split(separator);
        const year = parseInt(parts[2]);
        if (year > 50) {
            if (dayfirst === true) {
                return parts[0] + '.' + parts[1] + '.19' + parts[2];
            }else {
                return parts[1] + '.' + parts[0] + '.19' + parts[2];
            }
        }else {
            if (dayfirst === true) {
                return parts[0] + '.' + parts[1] + '.20' + parts[2];
            }else {
                return parts[1] + '.' + parts[0] + '.20' + parts[2];
            }
        }
        // the rest is converted to 20xx
    };

    format4DigitDate = (date, separator, dayfirst) => {
        // convert everything starting from 50 to 19xx
        const parts = date.split(separator);
        if(dayfirst === true) {
            return parts[0] + '.' + parts[1] + '.' + parts[2];
        }else {
            return parts[1] + '.' + parts[0] + '.' + parts[2];
        }
    };

    matchExact(r, str) {
        let match = str.match(r);
        return match && str === match[0];
    }

    onSubmit = async (event) => {
        event.preventDefault()

        this.setState({isLoading: true});

        const params_single = {
            "countryCode": "CZ",
            "b2b": "B2B",
            "vin": this.state.vin, //"TMBEB6NJ5KZ078731",
            "msrpTotal": Number(this.state.list_price), //"287600",
            "msrpUnit": "CZK",
            "initialRegistrationDate": this.state.registration, //"04.02.19",
            "odometer": Number(this.state.mileage), //"15539",
            "damagesTotal": Number(this.state.damage), //"8254",
            "damageUnit": "CZK",
            "odometerUnit": "km",
            "offerType": "auction",
            "salesDate": "",
            "salesChannel": "B2B",
            "contractType": "NEW",
            "uniqueId": "1168896",
            "dummy1": "",
            "dummy2": "",
            "dummy3": ""
        }

        const lines = this.state.text_area.split('\n');
        let newEntries = [];
        for (let i = 0; i < lines.length; i++) {
            let line = lines[i];
            if (line.trim() === '') {
                // ignore empty lines
                continue;
            }
            const newEntry = this.parseLine(line, i);
            if (newEntry) {
                newEntries.push(newEntry);
            }
        }

        try {
            const token = await getToken();
            const axiosConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'x-service-id': process.env.REACT_APP_X_SERVICE_ID
                }
            };
            if (this.state.single) {
                axios.post(process.env.REACT_APP_BE_URL, params_single, axiosConfig)
                    .then(result => {
                        // array containing the models and details
                        // this.setState({result: JSON.parse(result.data)});
                        this.setState({result: result.data});
                        console.log(result.data);
                        this.setState({isLoading: false});
                    })
                    .catch(err => {
                        console.log(err);
                        this.messages.show({severity: 'error', summary: 'Error', detail: err.message});
                        this.setState({isLoading: false});
                    });
            } else {
                var success = 1;
                this.setState({result_batch_pred: []});
                this.setState({result_batch_score: []});
                while (newEntries.length > 0) {
                    const slicedArray = newEntries.splice(0, 1);
                    await axios.post(process.env.REACT_APP_BE_URL, slicedArray[0], axiosConfig)
                        .then(result => {
                            // array containing the models and details
                            // this.setState({result: JSON.parse(result.data)});
                            this.setState({result_batch_pred: this.state.result_batch_pred.concat(result.data.marketValueAmount)});
                            this.setState({result_batch_score: this.state.result_batch_score.concat(result.data.score)});
                            console.log(result.data);
                        })
                        .catch(err => {
                            success = 0
                            console.log(err);
                            this.messages.show({severity: 'error', summary: 'Error', detail: err.message});
                        });
                }
                if (success === 0) {
                    this.setState({result_batch_pred: ['Error']});
                    this.setState({result_batch_score: ['Error']});
                }
                this.setState({isLoading: false});
            }
        } catch (e) {
            this.messages.show({severity: 'error', summary: 'Error', detail: e.message});
        }
    };

    GetResults_B2B() {
        const res = this.state.result_batch_pred;
        const res2 = this.state.result_batch_score;
        return (res).map((number, pos) => number+'\t'
            +Math.round(number*(1+this.state.batch_surcharge_s1[pos]))+'\t'
            +Math.round(number*(1+this.state.batch_surcharge_s2[pos]))+'\t'
            +this.getBatchScore(res2[pos])+'\n').join('');
    }
    getBatchScore(score){
        if (score && score > 24) {
            return "GREEN"
        } else if (score && score > 9) {
            return "YELLOW";
        } else {
            return "RED"
        }
    }

    getScore() {
        const score = this.state.result.score;
        if (score && score > 24) {
            return "GREEN"
        } else if (score && score > 9) {
            return "YELLOW";
        } else {
            return "RED"
        }
    }

    render() {
        return (
            <div>
                <main className="o-page-wrap  o-page-wrap--small">
                    <div className="o-page-wrap u-mb-small">
                        <div className="o-split o-split--top" >
                            <span></span>
                            <span style={{textAlign: "left", align:"left"}}>
                                            <article className="c-card c-card--large c-card--border" style={{textAlign: "left",  align:"left"}}>
                                                <p style={{color:'red', lineHeight: 0.1}}><small>Algorithm trained: ---</small></p>
                                                <p style={{color:'red', lineHeight: 0.1}}><small>Training data: ---</small></p>
                                                <p style={{color:'red', lineHeight: 0.1}}><small>Amount of Training data: ---</small></p>
                                                <p style={{color:'red', lineHeight: 0.1}}><small>Last updated: ---</small></p>
                                            </article>
                                        </span>
                        </div>
                    </div>
                    <div className="o-page-wrap u-mb-large">
                        <header className="o-even-slimmer-page-wrap  u-text-center">
                            <h1>PROTOTYPE</h1>
                            <p>Please insert the following values to get a price prediction:</p>
                            <br/>
                            <Messages ref={(el) => this.messages = el}></Messages>
                            <div id="login-form-banking"
                                 className="c-tab-content-container js-tab-container__content u-mb-none u-pb-none">
                                <form onSubmit={this.onSubmit}>
                                    <fieldset className="o-fieldset u-mb" style={{textAlign: "left"}}>
                                        <div className="o-split o-split--top" >
                                    <span>
                                        <h2>Single Vehicle</h2>
                                        <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="nama">VIN</label>
                                                <div className="c-input  ">
                                                </div>
                                            </div>
                                            <div className="o-layout__item  u-1/2">
                                                <input className="c-input__input" type="text" id="vin"
                                                       name="vin" value={this.state.vin}
                                                       onChange={(e) => {
                                                           this.setState({vin: e.target.value})
                                                       }}/>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="nama">1st Registration</label>
                                                <div className="c-input  ">
                                                </div>
                                            </div>
                                            <div className="o-layout__item  u-1/2">
                                                <input className="c-input__input" type="text" id="registration"
                                                       name="registration" value={this.state.registration}
                                                       onChange={(e) => {
                                                           this.setState({registration: e.target.value})
                                                       }}/>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="nama">Mileage</label>
                                                <div className="c-input  ">
                                                </div>
                                            </div>
                                            <div className="o-layout__item  u-1/2">
                                                <input className="c-input__input" type="text" id="mileage"
                                                       name="mileage" value={this.state.mileage}
                                                       onChange={(e) => {
                                                           this.setState({mileage: e.target.value})
                                                       }}/>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="nama">Damages (CZK)</label>
                                                <div className="c-input  ">
                                                </div>
                                            </div>
                                            <div className="o-layout__item  u-1/2">
                                                <input className="c-input__input" type="text" id="damage"
                                                       name="damage" value={this.state.damage}
                                                       onChange={(e) => {
                                                           this.setState({damage: e.target.value})
                                                       }}/>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="nama">List Price (CZK)</label>
                                                <div className="c-input  ">
                                                </div>
                                            </div>
                                            <div className="o-layout__item  u-1/2">
                                                <input className="c-input__input" type="text" id="list_price"
                                                       name="list_price" value={this.state.list_price}
                                                       onChange={(e) => {
                                                           this.setState({list_price: e.target.value})
                                                       }}/>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="nama">Surcharge (S1)</label>
                                                <div className="c-input  ">
                                                </div>
                                            </div>
                                            <div className="o-layout__item  u-1/2">
                                                <input className="c-input__input" type="text" id="surcharge"
                                                       name="surcharge" value={this.state.surcharge_s1}
                                                       onChange={(e) => {
                                                           this.setState({surcharge_s1: e.target.value})
                                                       }}/>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/2">
                                                <label htmlFor="nama">Surcharge (S2)</label>
                                                <div className="c-input  ">
                                                </div>
                                            </div>
                                            <div className="o-layout__item  u-1/2">
                                                <input className="c-input__input" type="text" id="surcharge"
                                                       name="surcharge2" value={this.state.surcharge_s2}
                                                       onChange={(e) => {
                                                           this.setState({surcharge_s2: e.target.value})
                                                       }}/>
                                            </div>
                                            </div>
                                        </div>
                                    </span>
                                            <span style={{textAlign: "left", align:"left"}}>
                                            <h2>Result</h2>
                                            <article className="c-card c-card--large c-card--border" style={{textAlign: "left",  align:"left"}}>
                                                {this.state.result ?
                                                    <div className="c-card__body">
                                                        <p>Prediction: {this.state.result.marketValueAmount} <span>CZK</span></p>
                                                        <p>Pred-S1: {Math.round((this.state.result.marketValueAmount+(this.state.result.marketValueAmount*this.state.surcharge_s1)))} <span>CZK</span></p>
                                                        <p>Pred-S2: {Math.round((this.state.result.marketValueAmount+(this.state.result.marketValueAmount*this.state.surcharge_s2)))} <span>CZK</span></p>
                                                        <p>Score: {this.getScore()}</p>
                                                    </div>
                                                    : null}
                                            </article>
                                        </span>
                                        </div>
                                        <br/>
                                        <br/>
                                        <div className="o-split">
                                            <div className="o-fieldset__row">
                                                <div className="o-layout">
                                                    <div className="o-layout__item  u-1/1">
                                                        <button className="c-btn" type="submit" onClick={() => this.setState({single: true})}
                                                                disabled={this.state.isLoading}>
                                                            <span className="c-btn__text">Submit</span>
                                                            {this.state.isLoading ?
                                                                <div className="c-spinner c-spinner--small">
                                                                    <svg>
                                                                        <circle className="c-spinner__indicator" cx="9"
                                                                                cy="9"
                                                                                r="8"
                                                                                fill="none"/>
                                                                    </svg>
                                                                </div>
                                                                : null}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <span style={{textAlign: "center", align:"center"}}>
                                                <h2>Batch</h2>
                                                <label htmlFor="mileage">VIN / 1stRegistration / Mileage / Damages / ListPrice / Surcharge(S1) / Surcharge(S2)</label>
                                                    <textarea className="c-input__input" type="textarea" id="text_area"
                                                              style={{resize: 'vertical', width:1050, height:150}}
                                                           name="text_area" value={this.state.text_area}
                                                           onChange={(e) => {
                                                               this.setState({text_area: e.target.value})
                                                           }} required/>
                                            </span>
                                                <span style={{textAlign: "center", align:"center"}}>
                                                    <h2>Results</h2>
                                                </span>
                                                <div className="o-split">
                                                    <div className="o-layout__item  u-1/1">
                                                        <label htmlFor="b2b">Predictions / Predictions-S1 / Predictions-S2 / Score</label>
                                                        <textarea id="noter-text-area" name="textarea"
                                                                  style={{resize: 'vertical', width:1000, height:150}}
                                                                  value={this.GetResults_B2B()}/>
                                                    </div>
                                                </div>
                                        </div>
                                        <br/>
                                        <br/>
                                        <div className="o-split">
                                            <div className="o-fieldset__row">
                                                <div className="o-layout">
                                                    <div className="o-layout__item  u-1/1">
                                                        <button className="c-btn" type="submit" onClick={() => this.setState({single: false, batch_surcharge_s1: [], batch_surcharge_s2: []})}
                                                                disabled={this.state.isLoading}>
                                                            <span className="c-btn__text">Submit</span>
                                                            {this.state.isLoading ?
                                                                <div className="c-spinner c-spinner--small">
                                                                    <svg>
                                                                        <circle className="c-spinner__indicator" cx="9"
                                                                                cy="9"
                                                                                r="8"
                                                                                fill="none"/>
                                                                    </svg>
                                                                </div>
                                                                : null}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </header>
                    </div>
                </main>
            </div>
        );
    }
}
export default Demo_cz











