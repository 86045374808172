import React from 'react'
import axios from "axios";
import {Messages} from "primereact/messages";
import Select from 'react-select';
import { getToken } from "../../utils";


class Demo_recommendation_engine extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            Erstzulassung: '01.01.2020',
            resultCount: 50,
            /*Aktenherkunft: '8X1',
            MBV_Code: 'KG3354',
            sale_date: '2017-07-25T00:00:00.000000000',
            kw: '100',
            brand: 'Audi',
            first_register: '2020,1,1',
            cap_price: 15000,
            listenpreis_g: '15000',
            mileage: 50000,
            color: 'Gold',
            sales_channel: 'Auction',
            hdm: 'Yes',
            pr: '0',*/
        };
    }
    onSubmit = async (event) => {
        event.preventDefault();
        const pimp_icons_vals = ['Eq_AIRSUSP', 'Eq_ALU', 'Eq_TEL', 'Eq_REARCAM', 'Eq_AHK', 'Eq_RLINE',
            'Eq_SONDER','Eq_AUT','Eq_SHZ','Eq_SLINE','Eq_ACAUTO','Eq_AWD','Eq_STDHZG','Eq_BUSINESS',
            'Eq_XENON','Eq_AC','Eq_WINTER','Eq_ESD','Eq_TRUCKREG','Eq_ASSI','Eq_SOUND','Eq_PDC',
            'Eq_LEDER','Eq_NAVI','Eq_GRA'];


        this.setState({isLoading: true});

        let values = [];
        let pos = 0;
        //let checkedBoxes = document.querySelectorAll('input[type=checkbox]:checked');
        //for(let i = 0; i<checkedBoxes.length;i++){
        //  values = values + checkedBoxes[i].value + ';'
        //}

        for(let i = 0; i < pimp_icons_vals.length ;i++){
            let checkbox = document.getElementById(pimp_icons_vals[i]);
            if (checkbox.checked && checkbox.value!=null)
                values[pos] = checkbox.value;
                pos++;
        }

        // axios call
        const params = {
            "Modelljahr": Number.parseInt(this.state.Modelljahr),
            "resultCount": Number.parseInt(this.state.resultCount),
            "Aktenherkunft": this.state.file_origin_options,
            "MBV Code": this.state.MBV_Code,
            "Erstzulassung":this.state.Erstzulassung,
            "KmStand":this.state.KmStand,
            "Basis_LP":this.state.LP_Basis,
            "OPV":this.state.OPV,
            "OPM":this.state.OPM,
            "Listenpreis_Farbe":this.state.LP_Farbe,
            "Schaeden":this.state.Schaeden,
            "pimp_icons" : values,
        };

        try {
            const token = await getToken();
            const axiosConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'x-service-id': process.env.REACT_APP_X_SERVICE_ID
                }
            };
            axios.post(process.env.REACT_APP_BE_URL, params, axiosConfig)
                .then(result => {
                    // array containing the models and details
                    // this.setState({result: JSON.parse(result.data)});
                    this.setState({result: result.data});
                    console.log(result.data);
                    this.setState({isLoading: false});
                })
                .catch(err => {
                    console.log(err);
                    this.messages.show({ severity: 'error', summary: 'Error', detail: err.message });
                    this.setState({isLoading: false});
                });
        } catch (e) {
            this.messages.show({ severity: 'error', summary: 'Error', detail: e.message });
        }
    };


    render() {
        if(this.state.result) {
            var items = this.state.result.map((items) => (<li>{items}</li>));
        }

        const cylinder_options = [
            { label: "V6", value: "V6" },
            { label: "V8", value: "V8" },
            { label: "W10", value: "W10" },
            { label: "V12", value: "V12" },
            { label: "others", value: "nan" },
        ];

        const motortyp_options = [
            { label: "TFSI", value: "TFSI" },
            { label: "TDI", value: "TDI" },
            { label: "TSI", value: "TSI" },
            { label: "TGI", value: "TGI" },
            { label: "FSI", value: "FSI" },
            { label: "MPI", value: "MPI" },
            { label: "HTP", value: "HTP" },
            { label: "others", value: "nan" },
        ];

        const drive_options = [
            { label: "4Motion", value: "4Motion" },
            { label: "quattro", value: "quattro" },
            { label: "4X4", value: "4X4" },
            { label: "4Drive", value: "4Drive" },
            { label: "others", value: "nan" },
        ];

        const gear_options = [
            { label: "multitronic", value: "multitronic" },
            { label: "DSG", value: "DSG" },
            { label: "S", value: "S" },
            { label: "tronic", value: "tronic" },
            { label: "tiptronic", value: "tiptronic" },
            { label: "Automatik", value: "Automatik" },
            { label: "Shiftmatic", value: "Shiftmatic" },
            { label: "ASG", value: "ASG" },
            { label: "others", value: "nan" },
        ];

        const num_gear_options = [
            { label: "6-Gang", value: "6-Gang" },
            { label: "5-Gang", value: "5-Gang" },
            { label: "7-Gang", value: "7-Gang" },
            { label: "8-Gang", value: "8-Gang" },
            { label: "8-Stufen", value: "8-Stufen" },
            { label: "1-Gang", value: "1-Gang" },
            { label: "others", value: "nan" },
        ];

        const transmission_options = [
            { label: "M", value: "M" },
            { label: "A", value: "A" },
            { label: "H", value: "H" },
            { label: "others", value: "nan" },
        ];

        const drive_search_options = [
            { label: "W", value: "W" },
            { label: "A", value: "A" },
            { label: "others", value: "nan" },
        ];

        const body_shape_options = [
            { label: "Avant", value: "Avant" },
            { label: "Kasten 3000 mm", value: "Kasten 3000 mm" },
            { label: "Variant", value: "Variant" },
            { label: "Kasten 3400 mm", value: "Kasten 3400 mm" },
            { label: "Kombi 3400 mm", value: "Kombi 3400 mm" },
            { label: "Kombi 3000 mm", value: "Kombi 3000 mm" },
            { label: "Coupe", value: "Coupe" },
            { label: "Kombi", value: "Kombi" },
            { label: "Lim.", value: "Lim." },
            { label: "Kastenwagen", value: "Kastenwagen" },
            { label: "Kombi Maxi", value: "Kombi Maxi" },
            { label: "3400 mm", value: "3400 mm" },
            { label: "Cabriolet", value: "Cabriolet" },
            { label: "Kastenwagen Maxi", value: "Kastenwagen Maxi" },
            { label: "Combi", value: "Combi" },
            { label: "Einzelkabine 3665 mm", value: "Einzelkabine 3665 mm" },
            { label: "Kasten 3665 mm", value: "Kasten 3665 mm" },
            { label: "Kasten 3250 mm", value: "Kasten 3250 mm" },
            { label: "Doppelkabine 3400 mm", value: "Doppelkabine 3400 mm" },
            { label: "Kasten HD 4325 mm", value: "Kasten HD 4325 mm" },
            { label: "Roadster", value: "Roadster" },
            { label: "ST", value: "ST" },
            { label: "Einzelkabine 3000 mm", value: "Einzelkabine 3000 mm" },
            { label: "Einzelkabine 4325 mm", value: "Einzelkabine 4325 mm" },
            { label: "Double Cab", value: "Double Cab" },
            { label: "Doppelkabine 3665 mm", value: "Doppelkabine 3665 mm" },
            { label: "Single Cab", value: "Single Cab" },
            { label: "Spaceback", value: "Spaceback" },
            { label: "Einzelkabine 3400 mm", value: "Einzelkabine 3400 mm" },
            { label: "Doppelkabine Pritschenwagen 3665 mm", value: "Doppelkabine Pritschenwagen 3665 mm" },
            { label: "Kombi 3665 mm", value: "Kombi 3665 mm" },
            { label: "Spyder", value: "Spyder" },
            { label: "Kasten 3640 mmm", value: "Kasten 3640 mmm" },
            { label: "others", value: "nan" },
        ];

        const engine_efficiency_options = [
            { label: "BMT", value: "BMT" },
            { label: "BiFuel", value: "BiFuel" },
            { label: "Hybrid", value: "Hybrid" },
            { label: "EcoFuel", value: "EcoFuel" },
            { label: "BlueTDI", value: "BlueTDI" },
            { label: "SCR", value: "SCR" },
            { label: "BMT SCR", value: "BMT SCR" },
            { label: "flexible fuel", value: "flexible fuel" },
            { label: "clean diesel", value: "clean diesel" },
            { label: "Green tec", value: "Green tec" },
            { label: "Ecomotive", value: "Ecomotive" },
            { label: "BMT EcoFuel", value: "BMT EcoFuel" },
            { label: "cylinder on demand", value: "cylinder on demand" },
            { label: "ultra", value: "ultra" },
            { label: "ultra cylinder on demand", value: "ultra cylinder on demand" },
            { label: "G-TEC", value: "G-TEC" },
            { label: "ACT", value: "ACT" },
            { label: "e-tron", value: "e-tron" },
            { label: "g-tron", value: "g-tron" },
            { label: "BlueMotion", value: "BlueMotion" },
            { label: "GreenLine", value: "GreenLine" },
            { label: "others", value: "nan" },
        ];

        const equipment_line_options = [
            { label: "Attraction", value: "Attraction" },
            { label: "Trendline", value: "Trendline" },
            { label: "Ambition", value: "Ambition" },
            { label: "Comfortline", value: "Comfortline" },
            { label: "Highline", value: "Highline" },
            { label: "Ambiente", value: "Ambiente" },
            { label: "GTD", value: "GTD" },
            { label: "Startline", value: "Startline" },
            { label: "GTI", value: "GTI" },
            { label: "CrossGolf", value: "CrossGolf" },
            { label: "CrossPolo", value: "CrossPolo" },
            { label: "CrossTouran", value: "CrossTouran" },
            { label: "Sport & Style", value: "Sport & Style" },
            { label: "highline", value: "highline" },
            { label: "Track & Field", value: "Track & Field" },
            { label: "R", value: "R" },
            { label: "Track & Style", value: "Track & Style" },
            { label: "Trend & Fun", value: "Trend & Fun" },
            { label: "sport", value: "sport" },
            { label: "Scout", value: "Scout" },
            { label: "take up!", value: "take up!" },
            { label: "move up!", value: "move up!" },
            { label: "design", value: "design" },
            { label: "high up!", value: "high up!" },
            { label: "Alltrack", value: "Alltrack" },
            { label: "TTS", value: "TTS" },
            { label: "Beach", value: "Beach" },
            { label: "Elegance", value: "Elegance" },
            { label: "Reference", value: "Reference" },
            { label: "R-Line", value: "R-Line" },
            { label: "RS", value: "RS" },
            { label: "Active", value: "Active" },
            { label: "GL", value: "GL" },
            { label: "Basic", value: "Basic" },
            { label: "Sport", value: "Sport" },
            { label: "Laurin & Klement", value: "Laurin & Klement" },
            { label: "FR-Line", value: "FR-Line" },
            { label: "I-Tech", value: "I-Tech" },
            { label: "R WRC", value: "R WRC" },
            { label: "cross up!", value: "cross up!" },
            { label: "BlueGT", value: "BlueGT" },
            { label: "Advantage", value: "Advantage" },
            { label: "GTS", value: "GTS" },
            { label: "Chic", value: "Chic" },
            { label: "Monte Carlo", value: "Monte Carlo" },
            { label: "X-Perience", value: "X-Perience" },
            { label: "Plus", value: "Plus" },
            { label: "competition", value: "competition" },
            { label: "Dune", value: "Dune" },
            { label: "Design", value: "Design" },
            { label: "Cupra", value: "Cupra" },
            { label: "Xcellence", value: "Xcellence" },
            { label: "Outdoor Laurin & Klement", value: "Outdoor Laurin & Klement" },
            { label: "Cross", value: "Cross" },
            { label: "Conceptline", value: "Conceptline" },
            { label: "others", value: "nan" },
        ];

        const file_origin_options = [
            { label: "EVOG", value: 1 },
            { label: "EVLG", value: 2 },
            { label: "HILG", value: 3 },
            { label: "HILE", value: 4 },
            { label: "RACL", value: 5 },
            { label: "VIPS", value: 6 },
            { label: "EVLG", value: 7 },
            { label: "EVLE", value: 8 },
            { label: "EVOG", value: 9 },
            { label: "HILE", value: 10 },
            { label: "HILG", value: 11 },
            { label: "VIPS", value: 12 },
            { label: "4EVO", value: 13 },
            { label: "3ANK", value: 14 },
            { label: "1AHF", value: 15 },
            { label: "EVLE", value: 16 },
            { label: "DIRE", value: 17 },
            { label: "SOLE", value: 18 },
            { label: "HINL", value: 19 },
            { label: "2CZE", value: 20 },
            { label: "SOLE", value: 21 },
            { label: "RACL", value: 22 },
            { label: "2SVK", value: 23 },
            { label: "2ESP", value: 24 },
            { label: "2ITA", value: 25 },
            { label: "4GWB", value: 26 },
            { label: "4EVA", value: 27 },
            { label: "VAFA", value: 28 },
            { label: "2FRA", value: 29 },
            { label: "2BEL", value: 30 },
            { label: "NEUF", value: 31 },
            { label: "DIRE", value: 32 },
            { label: "INSL", value: 33 },
            { label: "1DBW", value: 34 },
            { label: "2NZZ", value: 35 },
            { label: "2POL", value: 36 },
            { label: "4EVO", value: 37 },
            { label: "2IXX", value: 38 },
            { label: "3ANK", value: 37 },
            { label: "1EXP", value: 40 },
            { label: "SOLE", value: 41 },
            { label: "4EVA", value: 42 },
            { label: "4GWB", value: 43 },
            { label: "2FRA", value: 44 },
            { label: "RENT", value: 45 },
            { label: "2ESP", value: 46 },
            { label: "2POL", value: 47 },
            { label: "2ITA", value: 48 },
            { label: "2SVK", value: 49 },
            { label: "INRE", value: 50 },
            { label: "INRE", value: 51 },
            { label: "RENT", value: 52 },
            { label: "INLE", value: 53 },
            { label: "2BEL", value: 54 },
            { label: "2CZE", value: 55 },
            { label: "INBA", value: 56 },
            { label: "INBC", value: 57 },
            { label: "NEUF", value: 58 },
            { label: "2SEA", value: 59 },
            { label: "LEAS", value: 60 },
            { label: "HINL", value: 61 },
        ];

    return (
            <div>
                <main className="o-page-wrap  o-page-wrap--small">
                    <div className="o-page-wrap u-mb-small">
                        <div className="o-split o-split--top" >
                            <span></span>
                         <span style={{textAlign: "left", align:"left"}}>
                                            <article className="c-card c-card--large c-card--border" style={{textAlign: "left",  align:"left"}}>
                                                <p style={{color:'red', lineHeight: 0.1}}><small>Algorithm trained: 30.04.2020.</small></p>
                                                <p style={{color:'red', lineHeight: 0.1}}><small>Training data: 01.2015 - 03.2019.</small></p>
                                                <p style={{color:'red', lineHeight: 0.1}}><small>Last updated: 25.06.2020.</small></p>
                                                <p style={{color:'red', lineHeight: 0.1}}><small>Only VW Brands possible.</small></p>
                                            </article>
                                        </span>
                        </div>
                    </div>
                    <div className="o-page-wrap u-mb-large">
                        <header className="o-even-slimmer-page-wrap  u-text-center">
                            <h1>PROTOTYPE</h1>
                            <p>Please insert the following values to get the recommendations:</p>
                            <br/>
                            <Messages ref={(el) => this.messages = el}></Messages>
                            <div id="login-form-banking"
                                 className="c-tab-content-container js-tab-container__content u-mb-none u-pb-none">
                                <form onSubmit={this.onSubmit}>
                                    <fieldset className="o-fieldset u-mb" style={{textAlign: "left"}}>
                                        <div className="" >
                                    <span>
                                        <h2>Parameter</h2>
                                        <h4>Generell</h4>
                                        <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/3">
                                                <label htmlFor="Erstzulassung">Erstzulassung</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="Erstzulassung"
                                                           name="Erstzulassung" value={this.state.Erstzulassung}
                                                           onChange={(e) => {
                                                               this.setState({Erstzulassung: e.target.value})
                                                           }} />
                                                </div>
                                            </div>

                                            <div className="o-layout__item  u-1/3">
                                                <label htmlFor="KmStand">Km Stand</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="KmStand"
                                                           name="KmStand" value={this.state.KmStand}
                                                           onChange={(e) => {
                                                               this.setState({KmStand: e.target.value})
                                                           }} />
                                                </div>
                                            </div>
                                            <div className="o-layout__item  u-1/3">
                                                <label htmlFor="Schaeden">Schaeden</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="Schaeden"
                                                           name="Schaeden" value={this.state.Schaeden}
                                                           onChange={(e) => {
                                                               this.setState({Schaeden: e.target.value})
                                                           }} />
                                                </div>
                                            </div>
                                            </div>
                                            <div className="o-layout">
                                            <div className="o-layout__item  u-1/3">
                                                <label htmlFor="LP_Basis">Listenpreis (Basis)</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="LP_Basis"
                                                           name="LP_Basis" value={this.state.LP_Basis}
                                                           onChange={(e) => {
                                                               this.setState({LP_Basis: e.target.value})
                                                           }} />
                                                </div>
                                            </div>
                                            <div className="o-layout__item  u-1/3">
                                                <label htmlFor="LP_Farbe">Listenpreis (Farbe)</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="LP_Farbe"
                                                           name="LP_Farbe" value={this.state.LP_Farbe}
                                                           onChange={(e) => {
                                                               this.setState({LP_Farbe: e.target.value})
                                                           }} />
                                                </div>
                                            </div>
                                            </div>
                                            <div className="o-layout">
                                            <div className="o-layout__item  u-1/3">
                                                <label htmlFor="OPV">OPV</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="OPV"
                                                           name="OPV" value={this.state.OPV}
                                                           onChange={(e) => {
                                                               this.setState({OPV: e.target.value})
                                                           }} />
                                                </div>
                                            </div>
                                            <div className="o-layout__item  u-1/3">
                                                <label htmlFor="OPM">OPM</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="OPM"
                                                           name="OPM" value={this.state.OPM}
                                                           onChange={(e) => {
                                                               this.setState({OPM: e.target.value})
                                                           }} />
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <h4>Modell</h4>
                                      <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/3">
                                                <label htmlFor="Aktenherkunft">Aktenherkunft</label>
                                                   <Select className="custom-class" type="text" id="body_shape_options"
                                                           name="body_shape_options" value={file_origin_options.label}
                                                           onChange={(e) => {
                                                               this.setState({file_origin_options: e.label})
                                                           }} rquired
                                                           options={ file_origin_options } />
                                            </div>:)

                                            <div className="o-layout__item  u-1/3">
                                                <label htmlFor="MBV_Code">MBV Code</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="MBV_Code"
                                                           name="MBV_Code" value={this.state.MBV_Code}
                                                           onChange={(e) => {
                                                               this.setState({MBV_Code: e.target.value})
                                                           }} />
                                                </div>
                                            </div>
                                            <div className="o-layout__item  u-1/3">
                                                <label htmlFor="Modelljahr">Modelljahr</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="Modelljahr"
                                                           name="Modelljahr" value={this.state.Modelljahr}
                                                           onChange={(e) => {
                                                               this.setState({Modelljahr: e.target.value})
                                                           }} />
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </span>
                                        </div>
                                        <br/>
                                        <h4>Pimp Icons</h4>
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="Eq_AIRSUSP" id="Eq_AIRSUSP" value="Eq_AIRSUSP"/>
                                                        <span className="c-checkbox__label">AIRSUSP</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="Eq_ALU" id="Eq_ALU" value="Eq_ALU"/>
                                                        <span className="c-checkbox__label">ALU</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="Eq_TEL" id="Eq_TEL" value="Eq_TEL"/>
                                                        <span className="c-checkbox__label">TEL</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="Eq_REARCAM" id="Eq_REARCAM" value="Eq_REARCAM"/>
                                                        <span className="c-checkbox__label">REARCAM</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="Eq_AHK" id="Eq_AHK" value="Eq_AHK"/>
                                                        <span className="c-checkbox__label">AHK</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="Eq_GRA" id="Eq_GRA" value="Eq_GRA"/>
                                                        <span className="c-checkbox__label">GRA</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="Eq_RLINE" id="Eq_RLINE" value="Eq_RLINE"/>
                                                        <span className="c-checkbox__label">RLINE</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="Eq_SONDER" id="Eq_SONDER" value="Eq_SONDER"/>
                                                        <span className="c-checkbox__label">SONDER</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="Eq_AUT" id="Eq_AUT" value="Eq_AUT"/>
                                                        <span className="c-checkbox__label">AUT</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="Eq_SHZ" id="Eq_SHZ" value="Eq_SHZ"/>
                                                        <span className="c-checkbox__label">SHZ</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="Eq_SLINE" id="Eq_SLINE" value="Eq_SLINE"/>
                                                        <span className="c-checkbox__label">SLINE</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="Eq_ACAUTO" id="Eq_ACAUTO" value="Eq_ACAUTO"/>
                                                        <span className="c-checkbox__label">ACAUTO</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="Eq_AWD" id="Eq_AWD" value="Eq_AWD"/>
                                                        <span className="c-checkbox__label">AWD</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  "type="checkbox" name="Eq_STDHZG" id="Eq_STDHZG" value="Eq_STDHZG"/>
                                                        <span className="c-checkbox__label">STDHZG</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="Eq_BUSINESS" id="Eq_BUSINESS" value="Eq_BUSINESS"/>
                                                        <span className="c-checkbox__label">BUSINESS</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="Eq_XENON" id="Eq_XENON" value="Eq_XENON"/>
                                                        <span className="c-checkbox__label">XENON</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="Eq_AC" id="Eq_AC" value="Eq_AC"/>
                                                        <span className="c-checkbox__label">AC</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="Eq_WINTER" id="Eq_WINTER" value="Eq_WINTER"/>
                                                        <span className="c-checkbox__label">WINTER</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="Eq_ESD" id="Eq_ESD" value="Eq_ESD"/>
                                                        <span className="c-checkbox__label">ESD</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="Eq_TRUCKREG" id="Eq_TRUCKREG" value="Eq_TRUCKREG"/>
                                                        <span className="c-checkbox__label">TRUCKREG</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="Eq_ASSI" id="Eq_ASSI" value="Eq_ASSI"/>
                                                        <span className="c-checkbox__label">ASSI</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="Eq_SOUND" id="Eq_SOUND" value="Eq_SOUND"/>
                                                        <span className="c-checkbox__label">SOUND</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="Eq_NAVI" id="Eq_NAVI" value="Eq_NAVI"/>
                                                        <span className="c-checkbox__label">NAVI</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="Eq_PDC" id="Eq_PDC" value="Eq_PDC"/>
                                                        <span className="c-checkbox__label">PDC</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox" name="Eq_LEDER" id="Eq_LEDER" value="Eq_LEDER"/>
                                                        <span className="c-checkbox__label">LEDER</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <br>
                                        </br>
                                        <h4># Ergebnisse</h4>
                                        <div className="o-fieldset__row">
                                                <div className="o-layout">
                                                    <div className="o-layout__item  u-1/3 ">
                                                        <input className="c-input__input" type="text" id="resultCount"
                                                               name="resultCount" value={this.state.resultCount}
                                                               onChange={(e) => {
                                                                   this.setState({resultCount: Number.parseInt(e.target.value)})
                                                               }} />
                                                    </div>
                                                </div>
                                        </div>
                                        <br/>
                                        <div className="o-split">
                                            <div className="o-fieldset__row">
                                                <div className="o-layout">
                                                    <div className="o-layout__item  u-1/1">
                                                        <button className="c-btn" type="submit"
                                                                disabled={this.state.isLoading}>
                                                            <span className="c-btn__text">Submit</span>
                                                            {this.state.isLoading ?
                                                                <div className="c-spinner c-spinner--small">
                                                                    <svg>
                                                                        <circle className="c-spinner__indicator" cx="9"
                                                                                cy="9"
                                                                                r="8"
                                                                                fill="none"/>
                                                                    </svg>
                                                                </div>
                                                                : null}
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <span style={{textAlign: "left", align: "left"}}>
                                            <br>
                                            </br>
                                            <h2>Result</h2>
                                            <article className="c-card c-card--large c-card--border"
                                                     style={{textAlign: "left", align: "left"}}>
                                                {this.state.result ?
                                                    <div className="c-card__body">
                                                        <ul>
                                                            {items}
                                                        </ul>
                                                    </div>
                                                    : null}
                                            </article>
                                        </span>
                                    </fieldset>
                                </form>
                            </div>
                        </header>
                    </div>
                </main>
            </div>
        );
    }
}
export default Demo_recommendation_engine






