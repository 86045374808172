import React, { Component } from 'react';

import {
    BrowserRouter as Router,
    Route,
    Redirect,
    Switch
} from 'react-router-dom';
import { Auth } from "aws-amplify";

import './App.css';

import Header from './container/Header/Header'
import Footer from './container/Footer/Footer'
import Demo from './container/Demo/Demo'
import Demo_uk from './container/Demo/Demo_uk'
import Demo_spain from './container/Demo/Demo_spain'
import Demo_cz from './container/Demo/Demo_cz'
import Demo_b2c from './container/Demo/Demo_b2c'
import Demo_b2c_es from './container/Demo/Demo_b2c_es'
import Audit from './container/Audit/Audit'
import Main from './container/Main/Main'
import Demo_recommendation_engine from './container/Recommendation_Engine/Demo_recommendation_engine'
import Login from './container/Login/Login'
import ChangePassword from './container/Login/ChangePassword';
import UploadCZ from './container/UploadCZ/UploadCZ';

import {connect} from "react-redux";

class App extends Component {

  constructor(props) {
      super(props);

      this.state = {
          isAuthenticating: true,
          isLoading: false,
      };

      // need to bind them for some reason even though it is an arrow function
      this.onSignIn = this.onSignIn.bind(this);
      this.onLogout = this.onLogout.bind(this);
  }


  async componentDidMount() {
        // check the user session if there already is an authenticated user

        try {
            const user = await Auth.currentAuthenticatedUser();
            this.props.onSetUser(user);

            this.props.onSetIsAdmin(false);

            if (user.signInUserSession.accessToken.payload['cognito:groups']) {
                this.checkGroups(user.signInUserSession.accessToken.payload['cognito:groups']);
            }

            Auth.currentUserInfo()
                .then(result => {
                    let country_code = result.attributes['custom:country_code'];
                    if(country_code) {
                        this.props.onSetCountryCode(country_code);
                    }
                })
                .catch(err => {
                    console.log(err);
                })

        } catch(err) {
            if (err !== 'No current user') {
                this.props.onSetUser(null);
            }
        }
        await Promise.resolve();
        this.setState({ isAuthenticating: false });
  }

  checkGroups (groups) {
        this.props.onSetUserGroups(groups);
        if(groups.indexOf('Admin_PA') > -1) {
            this.props.onSetIsAdmin(true);
        }
        if(groups.indexOf('Extended_User_PA') > -1){
            this.props.onSetIsExtendedUser(true);
        }
  }

  onLogout = () => {
        Auth.signOut()
            .then(() => {
                this.props.onSetUser(null);
                this.props.onSetIsAdmin(false);
                this.props.onSetIsExtendedUser(false);
                this.props.onSetCountryCode('');
                this.props.history.push('/');
            }).catch(err => {
            console.log(err);
        });
  };


  onSignIn = (user) => {

        this.setState({
            isLoading: false,
            isAuthenticating: false,
        });
        this.props.onSetUser(user);

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            // a redirect is triggered from Login component
        } else {
            if (user.signInUserSession.accessToken.payload['cognito:groups']) {
                this.checkGroups(user.signInUserSession.accessToken.payload['cognito:groups']);
            }
            Auth.currentUserInfo()
                .then(result => {
                    let country_code = result.attributes['custom:country_code'];
                    if(country_code) {
                        this.props.onSetCountryCode(country_code);
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        }
  };

  render() {
        const childProps = {
            version: this.props.version,
            onSignIn: this.onSignIn,
            onLogout: this.onLogout
        };

        // unauthenticated routes
        let routes = (
            <Switch>
                <Route  exact path="/" render={ (props) => <Login {...props} {...childProps}/>} />
                <Route  path="/change_password" render={ (props) => <ChangePassword {...props} {...childProps} />} />
                <Route  path="/start" render={ (props) => <ChangePassword {...props} {...childProps} />} />
                <Redirect to="/" />
            </Switch>
        );

        // authenticated routes
        if (this.props.user && !this.props.isAdmin && !this.props.isExtendedUser && this.props.countryCode==="GER") {
            routes = (
                <Switch>
                    <Route  exact path="/" render={ (props) => <Login {...props} {...childProps}/>} />
                    <Route  path="/demo_ger" render={ (props) => <Demo {...props} {...childProps}/>} />
                    <Route  path="/start" render={ (props) => <Demo {...props} {...childProps}/>} />
                    <Route  path="/change_password" render={ (props) => <ChangePassword {...props} {...childProps} />} />
                    <Redirect to="/" />
                </Switch>
            );
        }

      // authenticated routes
      if (this.props.user && !this.props.isAdmin && !this.props.isExtendedUser && this.props.countryCode==="UK") {
          routes = (
              <Switch>
                  <Route  exact path="/" render={ (props) => <Login {...props} {...childProps}/>} />
                  <Route  path="/demo_uk" render={ (props) => <Demo_uk {...props} {...childProps}/>} />
                  <Route  path="/start" render={ (props) => <Demo_uk {...props} {...childProps}/>} />
                  <Route  path="/change_password" render={ (props) => <ChangePassword {...props} {...childProps} />} />
                  <Redirect to="/" />
              </Switch>
          );
      }

      // authenticated routes
      if (this.props.user && !this.props.isAdmin && !this.props.isExtendedUser && this.props.countryCode==="ESP") {
          routes = (
              <Switch>
                  <Route  exact path="/" render={ (props) => <Login {...props} {...childProps}/>} />
                  <Route  path="/demo_spain" render={ (props) => <Demo_spain {...props} {...childProps}/>} />
                  <Route  path="/start" render={ (props) => <Demo_spain {...props} {...childProps}/>} />
                  <Route  path="/change_password" render={ (props) => <ChangePassword {...props} {...childProps} />} />
                  <Redirect to="/" />
              </Switch>
          );
      }

      // authenticated routes
      if (this.props.user && !this.props.isAdmin && !this.props.isExtendedUser && this.props.countryCode==="CZE") {
          routes = (
              <Switch>
                  <Route  exact path="/" render={ (props) => <Login {...props} {...childProps}/>} />
                  <Route  path="/demo_cz" render={ (props) => <Demo_cz {...props} {...childProps}/>} />
                  <Route  path="/start" render={ (props) => <Demo_cz {...props} {...childProps}/>} />
                  <Route  path="/upload_cz" render={ (props) => <UploadCZ {...props} {...childProps}/>} />
                  <Route  path="/change_password" render={ (props) => <ChangePassword {...props} {...childProps} />} />
                  <Redirect to="/" />
              </Switch>
          );
      }

      // authenticated routes
      if (this.props.user && !this.props.isAdmin && !this.props.isExtendedUser && this.props.countryCode==="B2C") {
          routes = (
              <Switch>
                  <Route  exact path="/" render={ (props) => <Login {...props} {...childProps}/>} />
                  <Route  path="/demo_b2c" render={ (props) => <Demo_b2c {...props} {...childProps}/>} />
                  <Route  path="/start" render={ (props) => <Demo_b2c {...props} {...childProps}/>} />
                  <Route  path="/change_password" render={ (props) => <ChangePassword {...props} {...childProps} />} />
                  <Redirect to="/" />
              </Switch>
          );
      }

      // authenticated routes
      if (this.props.user && !this.props.isAdmin && !this.props.isExtendedUser && this.props.countryCode==="B2C_ES") {
          routes = (
              <Switch>
                  <Route  exact path="/" render={ (props) => <Login {...props} {...childProps}/>} />
                  <Route  path="/demo_b2c_es" render={ (props) => <Demo_b2c_es {...props} {...childProps}/>} />
                  <Route  path="/start" render={ (props) => <Demo_b2c_es {...props} {...childProps}/>} />
                  <Route  path="/change_password" render={ (props) => <ChangePassword {...props} {...childProps} />} />
                  <Redirect to="/" />
              </Switch>
          );
      }

      // authenticated routes
      if (this.props.user && !this.props.isAdmin && !this.props.isExtendedUser && this.props.countryCode==="RECOMMENDATION") {
          routes = (
              <Switch>
                  <Route  exact path="/" render={ (props) => <Login {...props} {...childProps}/>} />
                  <Route  path="/demo_recommendation_engine" render={ (props) => <Demo_recommendation_engine {...props} {...childProps}/>} />
                  <Route  path="/start" render={ (props) => <Demo_recommendation_engine {...props} {...childProps}/>} />
                  <Route  path="/change_password" render={ (props) => <ChangePassword {...props} {...childProps} />} />
                  <Redirect to="/" />
              </Switch>
          );
      }

        // authenticated routes
        if (this.props.isExtendedUser && !this.props.isAdmin) {
            routes = (
                <Switch>
                    <Route  exact path="/" render={ (props) => <Login {...props} {...childProps}/>} />
                    <Route  path="/demo_ger" render={ (props) => <Demo {...props} {...childProps}/>} />
                    <Route  path="/start" render={ (props) => <Demo {...props} {...childProps}/>} />
                    <Route  path="/demo_uk" render={ (props) => <Demo_uk {...props} {...childProps}/>} />
                    <Route  path="/demo_spain" render={ (props) => <Demo_spain {...props} {...childProps}/>} />
                    <Route  path="/demo_cz" render={ (props) => <Demo_cz{...props} {...childProps}/>} />
                    <Route  path="/upload_cz" render={ (props) => <UploadCZ {...props} {...childProps}/>} />
                    <Route  path="/upload_b2c" render={ (props) => <Demo_b2c {...props} {...childProps}/>} />
                    <Route  path="/change_password" render={ (props) => <ChangePassword {...props} {...childProps} />} />
                    <Redirect to="/" />
                </Switch>
            );
        }

        // authenticated admins
        if (this.props.user && this.props.isAdmin) {
            routes = (
                <Switch>
                    <Route  exact path="/" render={ (props) => <Login {...props} {...childProps}/>} />
                    <Route  path="/main" render={ (props) => <Main {...props} {...childProps}/>} />
                    <Route  path="/start" render={ (props) => <Main {...props} {...childProps}/>} />
                    <Route  path="/demo_ger" render={ (props) => <Demo {...props} {...childProps}/>} />
                    <Route  path="/demo_uk" render={ (props) => <Demo_uk {...props} {...childProps}/>} />
                    <Route  path="/demo_spain" render={ (props) => <Demo_spain {...props} {...childProps}/>} />
                    <Route  path="/demo_cz" render={ (props) => <Demo_cz {...props} {...childProps}/>} />
                    <Route  path="/upload_cz" render={ (props) => <UploadCZ {...props} {...childProps}/>} />
                    <Route  path="/demo_b2c" render={ (props) => <Demo_b2c {...props} {...childProps}/>} />
                    <Route  path="/demo_b2c_es" render={ (props) => <Demo_b2c_es {...props} {...childProps}/>} />
                    <Route  path="/audit" render={ (props) => <Audit {...props} {...childProps}/>} />
                    <Route  path="/demo_recommendation_engine" render={ (props) => <Demo_recommendation_engine {...props} {...childProps}/>} />
                    <Route  path="/change_password" render={ (props) => <ChangePassword {...props} {...childProps} />} />
                    <Redirect to="/" />
                </Switch>
            );
        }

        // make sure the app does not render until the authentication state is clear!

        return ( !this.state.isAuthenticating &&
            <div className="o-root">
                <Router>
                    <div>
                        <Header {...this.props} {...childProps} />
                        {routes}
                        <Footer {...this.props} {...childProps} />
                    </div>
                </Router>
            </div>
        );
  }
}


const mapStateToProps = (state) => {
    return {
        countryCode: state.countryCode,
        user: state.user,
        userGroups: state.userGroups,
        isAdmin: state.isAdmin,
        isExtendedUser: state.isExtendedUser
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetCountryCode: (value) => dispatch({type: 'SET_COUNTRY_CODE', value}),
        onSetUser: (value) => dispatch({type: 'SET_USER', value}),
        onSetUserGroups: (value) => dispatch({type: 'REPLACE_GROUPS', value}),
        onSetIsAdmin: (value) => dispatch({type: 'SET_IS_ADMIN', value}),
        onSetIsExtendedUser: (value) => dispatch({type: 'SET_IS_EXTENDED_USER', value})
    };
};
// export default App;
export default connect(mapStateToProps, mapDispatchToProps)(App);

