import {connect} from "react-redux";



const initialState = {
    searchInputs: [],
    countryCode: '',
    selectedCurrency: 'EUR',
    selectedModifier: 1.0,
    userGroups: [],
    user: null,
    isAdmin: false,
    isExtendedUser: false,
};

const reducer = (state = initialState, action) => {

    switch(action.type) {
        case 'DELETE_SEARCH_INPUT':
            let newArray = state.searchInputs.slice();
            newArray.splice(action.value, 1)

            return {
                ...state,
                searchInputs: newArray
            };
        case 'DELETE_ALL_SEARCH_INPUTS':
            return {
                ...state,
                searchInputs: []
            };
        case 'ADD_SEARCH_INPUTS':
            return {
                ...state,
                // use concat instead of push as concat returns a new array --> always do immutable updates
                searchInputs: state.searchInputs.concat(action.value)
            };
        case 'REPLACE_SEARCH_INPUTS':
            return {
                ...state,
                searchInputs: action.value
            };
        case 'SET_COUNTRY_CODE':
            return {
                ...state,
                countryCode: action.value
            };
        case 'SET_CURRENCY':
            return {
                ...state,
                selectedCurrency: action.value
            };
        case 'SET_MODIFIER':
            return {
                ...state,
                selectedModifier: action.value
            };
        case 'REPLACE_GROUPS':
            return {
                ...state,
                userGroups: action.value
            };
        case 'SET_USER':
            return {
                ...state,
                user: action.value
            };
        case 'SET_IS_ADMIN':
            return {
                ...state,
                isAdmin: action.value
            };
        case 'SET_IS_EXTENDED_USER':
            return {
                ...state,
                isExtendedUser: action.value
            };
        default:
            return state;
    }
};

export default reducer;