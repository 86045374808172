import React from 'react'
import axios from "axios";
import {Messages} from "primereact/messages";
import Select from 'react-select';
// import 'bootstrap/dist/css/bootstrap.min.css';
import {Link} from "react-router-dom";
import { getToken } from "../../utils";
// import React, { useState } from 'react';
// import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


class Demo_spain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            modelyear: '13.0',
            brand: 'AUDI',
            typeOfCar: 'Berlina',
            fuel: 'Diesel',
            engine_kw: '105',
            kindOfSale: 'Web en Abierto',
            listPrice_EQ: '2502',
            doors: '4',
            co2_emission: '119',
            mileage: '86486',
            damages: '135',
            list_price: '32805',
            registration: '26.01.2015',
            mbv: '8K20SC',
        };
    }

    onSubmit = async (event) => {
        event.preventDefault();
        const pimp_icons_vals = ['pr', 'hds', 'auc', '_03', '_04', '_05', '_06', '_07', '_08', '_09', '_10', '_11', '_12', '_13', '_14', '_15', '_16', '_17'
            , '_18', '_19', '_20', '_21', '_22', '_23', '_24', '_25', '_26', '_27'];

        this.setState({isLoading: true});


        // axios call
        /*const params = {
            "modelyear": this.state.modelyear,
            "brand": this.state.brand,
            "fuel": this.state.fuel,
            "engine_kw": Number(this.state.engine_kw),
            "co2_emission": Number(this.state.co2_emission),
            "mileage": Number(this.state.mileage),
            "damages": Number(this.state.damages),
            "list_price": Number(this.state.list_price),
            "firstRegistration": this.state.registration,
            "doors": Number(this.state.doors),
            "listPrice_EQ": Number(this.state.listPrice_EQ),
            "typeOfCar": this.state.typeOfCar,
            "mbv": this.state.mbv,
            "kindOfSale": this.state.kindOfSale,
        };*/

        const params = {
            "modelyear": this.state.modelyear,
            "brand": this.state.brand,
            "fuel": this.state.fuel,
            "engine_kw": this.state.engine_kw,
            "co2_emission": this.state.co2_emission,
            "mileage": this.state.mileage,
            "damages": this.state.damages,
            "list_price": this.state.list_price,
            "firstRegistration": this.state.registration,
            "doors": this.state.doors,
            "listPrice_EQ": this.state.listPrice_EQ,
            "typeOfCar": this.state.typeOfCar,
            "mbv": this.state.mbv,
            "kindOfSale": this.state.kindOfSale,
        };

        try {
            const token = await getToken();
            const axiosConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'x-service-id': process.env.REACT_APP_X_SERVICE_ID
                }
            };
            axios.post(process.env.REACT_APP_BE_URL, params, axiosConfig)
                .then(result => {
                    // array containing the models and details
                    // this.setState({result: JSON.parse(result.data)});
                    this.setState({result: result.data});
                    console.log(result.data);
                    this.setState({isLoading: false});
                })
                .catch(err => {
                    console.log(err);
                    this.messages.show({severity: 'error', summary: 'Error', detail: err.message});
                    this.setState({isLoading: false});
                });
        } catch (e) {
            this.messages.show({severity: 'error', summary: 'Error', detail: e.message});
        }
    };


    render() {
        const brand_options = [
            {label: "AUDI", value: 1},
            {label: "SEAT", value: 2},
            {label: "SKODA", value: 3},
            {label: "VOLKSWAGEN", value: 4},
        ];

        const modelyear_options = [
            {label: "2010", value: "10.0"},
            {label: "2011", value: "11.0"},
            {label: "2012", value: "12.0"},
            {label: "2013", value: "13.0"},
            {label: "2014", value: "14.0"},
            {label: "2015", value: "15.0"},
            {label: "2016", value: "16.0"},
            {label: "2017", value: "17.0"},
            {label: "2018", value: "18.0"},
            {label: "2019", value: "19.0"},
            {label: "2020", value: "20.0"},
            {label: "Otros", value: "missing"},
        ];

        const typeOfCar_options = [
            {label: "Berlina", value: 1},
            {label: "Chasis", value: 2},
            {label: "Combi", value: 3},
            {label: "Convertible", value: 4},
            {label: "Coupe", value: 5},
            {label: "Furgon", value: 6},
            {label: "Monovolumen", value: 7},
            {label: "Pick-Up Doble Cabina", value: 8},
            {label: "Roadster", value: 9},
            {label: "Stationwagon", value: 10},
            {label: "Todo Terreno", value: 11},
        ];

        const fuel_options = [
            {label: "Corriente eléctrica", value: 1},
            {label: "Diesel", value: 2},
            {label: "Diesel y corriente eléctrica", value: 3},
            {label: "Gasolina", value: 4},
            {label: "Gasolina sin plomo", value: 5},
            {label: "Gasolina sin plomo y corriente eléctrica", value: 6},
            {label: "Gasolina y corriente eléctrica", value: 7},
            {label: "Gasolina/gas", value: 8},
        ];

        const kindOfSale_options = [
            {label: "KoS_Compra directa", value: 1},
            {label: "Concesionario Devolución Renting", value: 2},
            {label: "FS-CARS", value: 3},
            {label: "FS-CARS- Usuario Renting", value: 4},
            {label: "Lote / Automática ", value: 5},
            {label: "Lote / Autorización", value: 6},
            {label: "Puja / Automática", value: 7},
            {label: "Puja / Autorización", value: 8},
            {label: "Red Participadas", value: 8},
            {label: "Renting Red", value: 8},
            {label: "Usuario Renting", value: 8},
            {label: "Web en Abierto", value: 8},

        ];


        return (
            <div>
                <main className="o-page-wrap  o-page-wrap--small">
                    <div className="o-page-wrap u-mb-small">
                        <div className="o-split o-split--top">
                            <span></span>
                            <span style={{textAlign: "left", align: "left"}}>
                                            <article className="c-card c-card--large c-card--border"
                                                     style={{textAlign: "left", align: "left"}}>
                                                <p style={{color: 'red', lineHeight: 0.1}}><small>Algorithm trained: 30.06.2020</small></p>
                                                <p style={{color: 'red', lineHeight: 0.1}}><small>Training data: 01.03.2018 - 19.03.2020</small></p>
                                                <p style={{color: 'red', lineHeight: 0.1}}><small>Amount of Training data: 22.848</small></p>
                                                <p style={{color: 'red', lineHeight: 0.1}}><small>Last updated: 01.07.2020</small></p>
                                                <p style={{color: 'red', lineHeight: 0.1}}><small>VW Brands only.</small></p>
                                            </article>
                                        </span>
                        </div>
                    </div>
                    <div className="o-page-wrap u-mb-large">
                        <header className="o-even-slimmer-page-wrap  u-text-center">
                            <h1>PROTOTYPE</h1>
                            <p>Please insert the following values to get a price prediction:</p>
                            <br/>
                            <Messages ref={(el) => this.messages = el}></Messages>
                            <div id="login-form-banking"
                                 className="c-tab-content-container js-tab-container__content u-mb-none u-pb-none">
                                <form onSubmit={this.onSubmit}>
                                    <fieldset className="o-fieldset u-mb" style={{textAlign: "left"}}>
                                        <div className="o-split o-split--top">
                                    <span>
                                        <h2>Parameter</h2>
                                        <div className="o-fieldset__row">
                                            <div className="o-layout">
                                                <div className="o-layout__item  u-1/2">

                                                    <label htmlFor="brand">Brand</label>
                                                    <Select className="custom-class" type="text" id="brand"
                                                            name="brand" value={brand_options.label}
                                                            onChange={(e) => {
                                                                this.setState({brand: e.label})
                                                            }} required
                                                            options={brand_options}/>

                                                </div>
                                                <div className="o-layout__item  u-1/2">
                                                    <label htmlFor="mbv">MBV</label>
                                                    <div className="c-input  ">
                                                        <input className="c-input__input" type="text" id="mbv"
                                                               name="mbv" value={this.state.mbv}
                                                               onChange={(e) => {
                                                                   this.setState({mbv: e.target.value})
                                                               }} required/>
                                                    </div>
                                          </div>
                                            </div>
                                        </div>

                                        <div className="o-fieldset__row">
                                            <div className="o-layout">
                                                <div className="o-layout__item  u-1/2">

                                                    <label htmlFor="fuel">Fuel type</label>
                                                    <Select className="custom-class" type="text" id="fuel"
                                                            name="fuel" value={fuel_options.label}
                                                            onChange={(e) => {
                                                                this.setState({fuel: e.label})
                                                            }} required
                                                            options={fuel_options}/>

                                                </div>

                                                <div className="o-layout__item  u-1/2">
                                                    <label htmlFor="registration">First Registration</label>
                                                    <div className="c-input  ">
                                                        <input className="c-input__input" type="text" id="registration"
                                                               name="registration" value={this.state.registration}
                                                               onChange={(e) => {
                                                                   this.setState({registration: e.target.value})
                                                               }} required/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="o-fieldset__row">
                                            <div className="o-layout">
                                                <div className="o-layout__item  u-1/2">

                                                    <label htmlFor="mileage">Mileage</label>
                                              <div className="c-input  ">
                                                  <input className="c-input__input" type="text" id="mileage"
                                                         name="mileage" value={this.state.mileage}
                                                         onChange={(e) => {
                                                             this.setState({mileage: e.target.value})
                                                         }} required/>

                                              </div>
                                                </div>
                                                <div className="o-layout__item  u-1/2">
                                                    <label htmlFor="damages">Damage</label>
                                                    <div className="c-input  ">
                                                        <input className="c-input__input" type="text" id="damages"
                                                               name="damages" value={this.state.damages}
                                                               onChange={(e) => {
                                                                   this.setState({damages: e.target.value})
                                                               }} required/>
                                                    </div>
                                          </div>
                                            </div>
                                        </div>

                                        <div className="o-fieldset__row">
                                            <div className="o-layout">
                                                <div className="o-layout__item  u-1/2">
                                                    <label htmlFor="listPrice_EQ">List Price (Equipment)</label>
                                                    <div className="c-input  ">
                                                        <input className="c-input__input" type="text" id="listPrice_EQ"
                                                               name="listPrice_EQ" value={this.state.listPrice_EQ}
                                                               onChange={(e) => {
                                                                   this.setState({listPrice_EQ: e.target.value})
                                                               }} required/>
                                                    </div>

                                                </div>

                                                <div className="o-layout__item  u-1/2">
                                                    <label htmlFor="list_price">List Price</label>
                                              <div className="c-input  ">
                                                  <input className="c-input__input" type="text" id="list_price"
                                                         name="list_price" value={this.state.list_price}
                                                         onChange={(e) => {
                                                             this.setState({list_price: e.target.value})
                                                         }} required/>
                                              </div>
                                                </div>
                                            </div>
                                        </div>

                                         <div className="o-fieldset__row">
                                            <div className="o-layout">
                                                <div className="o-layout__item  u-1/2">
                                                    <label htmlFor="modelyear">Modelyear</label>
                                              <Select className="custom-class" type="text" id="modelyear"
                                                      name="modelyear" value={modelyear_options.label}
                                                      onChange={(e) => {
                                                          this.setState({modelyear: e.value})
                                                      }} required
                                                      options={modelyear_options}/>
                                                </div>

                                                <div className="o-layout__item  u-1/2">
                                                    <label htmlFor="typeOfCar">Type of Car</label>
                                                    <Select className="custom-class" type="text" id="typeOfCar"
                                                            name="typeOfCar" value={typeOfCar_options.label}
                                                            onChange={(e) => {
                                                                this.setState({typeOfCar: e.label})
                                                            }} required
                                                            options={typeOfCar_options}/>
                                          </div>
                                            </div>
                                        </div>
                                        <div className="o-fieldset__row">
                                            <div className="o-layout">
                                                <div className="o-layout__item  u-1/2">
                                                    <label htmlFor="kindOfSale">Origin</label>
                                              <Select className="custom-class" type="text" id="kindOfSale"
                                                      name="kindOfSale" value={kindOfSale_options.label}
                                                      onChange={(e) => {
                                                          this.setState({colour: e.label})
                                                      }} required
                                                      options={kindOfSale_options}/>
                                                </div>
                                                <div className="o-layout__item  u-1/2">
                                              <label htmlFor="co2_emission">CO2</label>
                                              <div className="c-input  ">
                                                   <input className="c-input__input" type="text" id="co2_emission"
                                                          name="co2_emission" value={this.state.co2_emission}
                                                          onChange={(e) => {
                                                              this.setState({co2_emission: e.target.value})
                                                          }} required/>
                                              </div>
                                          </div>
                                            </div>
                                        </div>
                                        <div className="o-fieldset__row">
                                            <div className="o-layout">
                                                <div className="o-layout__item  u-1/2">
                                                    <label htmlFor="doors">Number of Doors</label>
                                                    <div className="c-input  ">
                                                        <input className="c-input__input" type="text"
                                                               id="doors"
                                                               name="doors" value={this.state.doors}
                                                               onChange={(e) => {
                                                                   this.setState({doors: e.target.value})
                                                               }}/>
                                                    </div>
                                                </div>

                                                <div className="o-layout__item  u-1/2">
                                              <label htmlFor="engine_kw">Engine kW</label>
                                              <div className="c-input  ">
                                                  <input className="c-input__input" type="text" id="engine_kw"
                                                         name="engine_kw" value={this.state.engine_kw}
                                                         onChange={(e) => {
                                                             this.setState({engine_kw: e.target.value})
                                                         }} required/>
                                              </div>
                                          </div>
                                            </div>
                                        </div>


                                    </span>
                                            <span style={{textAlign: "left", align: "left"}}>
                                            <h2>Result</h2>
                                            <article className="c-card c-card--large c-card--border"
                                                     style={{textAlign: "left", align: "left"}}>
                                                {this.state.result ?
                                                    <div className="c-card__body">
                                                        <p>Prediction: {this.state.result.Prediction} <span>€</span></p>
                                                        <p>Score: {this.state.result.Score}</p>
                                                    </div>
                                                    : null}
                                            </article>
                                        </span>
                                        </div>
                                        <br/>
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox"
                                                               name="_00" id="_00" value="_"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox"
                                                               name="_01" id="_01" value="_"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox"
                                                               name="_02" id="_02" value="_"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox"
                                                               name="_03" id="_03" value="_"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox"
                                                               name="_04" id="_04" value="_"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="o-layout__item  u-1/6">
                                                <label className="c-checkbox">
                                                    <div className="c-input">
                                                        <input className="c-checkbox__input  " type="checkbox"
                                                               name="_05" id="_05" value="_"/>
                                                        <span className="c-checkbox__label">_</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="o-split">
                                            <div className="o-fieldset__row">
                                                <div className="o-layout">
                                                    <div className="o-layout__item  u-1/1">
                                                        <button className="c-btn" type="submit"
                                                                disabled={this.state.isLoading}>
                                                            <span className="c-btn__text">Submit</span>
                                                            {this.state.isLoading ?
                                                                <div className="c-spinner c-spinner--small">
                                                                    <svg>
                                                                        <circle className="c-spinner__indicator" cx="9"
                                                                                cy="9"
                                                                                r="8"
                                                                                fill="none"/>
                                                                    </svg>
                                                                </div>
                                                                : null}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </header>
                    </div>
                </main>
            </div>
        );
    }
}

export default Demo_spain
