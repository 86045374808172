import React from 'react'
import {Messages} from 'primereact/messages';
import { Auth } from "aws-amplify";
import {connect} from "react-redux";

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            isLoading: false
        };
    }

    onSubmit = event => {
        event.preventDefault();

        this.setState({isLoading: true});
        Auth.signIn(this.state.username, this.state.password)
            .then(user => {
                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    this.props.onSignIn(user);
                    // no need to set the loading state as this component is dismounted
                    this.props.history.push('/change_password');
                } else {
                    this.props.onSignIn(user);
                    this.props.history.push('/start');
                }

            }).catch(err => {
            console.log(err);
            this.messages.show({ severity: 'error', summary: 'Login Failure', detail: err.message });
            this.setState({isLoading: false});
        });
    };

    render() {
        return (
            <main className="o-page-wrap  o-page-wrap--small">
                <div className="o-page-wrap u-mb-large">
                    <header className="o-even-slimmer-page-wrap  u-text-center">
                        <h1>Login</h1>
                        <p>Please contact the Data and Analytics Unit, if you have any trouble logging in.</p>

                        <Messages ref={(el) => this.messages = el}></Messages>

                        <div id="login-form-banking"
                             className="c-tab-content-container js-tab-container__content u-mb-none u-pb-none">

                            <form onSubmit={this.onSubmit}>
                                <fieldset className="o-fieldset u-mb">
                                    <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/1">
                                                <label htmlFor="username">Username or E-Mail</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="text" id="username"
                                                           name="username" value={this.state.username}
                                                           onChange={(e) => {
                                                               this.setState({username: e.target.value})
                                                           }} required/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/1">
                                                <label htmlFor="password">Password</label>
                                                <div className="c-input  ">
                                                    <input className="c-input__input" type="password" id="password"
                                                           name="password" value={this.state.password}
                                                           onChange={(e) => {
                                                               this.setState({password: e.target.value})
                                                           }} required/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="o-split">
                                        <div className="o-fieldset__row">
                                            <div className="o-layout">
                                                <div className="o-layout__item  u-1/1">
                                                    <button className="c-btn" type="submit"
                                                            disabled={this.state.isLoading}>
                                                        <span className="c-btn__text">Submit</span>
                                                        {this.state.isLoading ?
                                                            <div className="c-spinner c-spinner--small">
                                                                <svg>
                                                                    <circle className="c-spinner__indicator" cx="9"
                                                                            cy="9"
                                                                            r="8"
                                                                            fill="none"/>
                                                                </svg>

                                                            </div>
                                                            : null}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>

                        <p>If you forgot your password, please contact the support team: dna@vwfs.io</p>
                    </header>
                </div>
            </main>
        )
    }
}
export default Login

/*
const mapStateToProps = (state) => {
    return {
        countryCode: state.countryCode,
        user: state.user,
        userGroups: state.userGroups,
        isAdmin: state.isAdmin,
        isExtendedUser: state.isExtendedUser
    };
};

export default connect(mapStateToProps)(Login);*/