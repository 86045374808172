import React from "react";
import {Link} from "react-router-dom";
import {Auth} from 'aws-amplify';

import {ProgressBar} from 'primereact/progressbar';
import {Messages} from "primereact/messages";
import axios from "axios";

import UploadedFile from '../../Components/UploadedFile/UploadedFile'
import { getToken } from "../../utils";


class UploadCZ extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFileUploaded: false,
            dataTableUploadInProgress: false,
            dataTableUploadProgress: 0.0,
            fileProcessing: false,
            fileParsed: false,
            status: null,
            uploadedFiles: []
        }
    }

    componentDidMount() {
        // retrieve uploaded files
        getToken()
            .then((token) => {
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                        'x-service-id': process.env.REACT_APP_X_SERVICE_ID
                    }
                };
                axios.get(process.env.REACT_APP_LIST_FILES_ENDPOINT, axiosConfig)
                    .then(result => {
                        if (result.data) {
                            this.setState({
                                uploadedFiles: result.data
                            });
                        }

                    })
                    .catch(err => {
                        this.messages.show({severity: 'error', summary: 'Upload Error', detail: err.message});
                    })
            })
            .catch(err => {
                this.messages.show({severity: 'error', summary: 'Error', detail: err.message});
            });
    }

    onDocumentSelected = (event) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile) {
            return;
        }

        const params = {
            filename: selectedFile.name,
            filesize: selectedFile.size,
        };

        this.setState({
            dataTableUploadInProgress: true,
            fileParsed: false
        });

        getToken()
            .then((token) => {
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                        'x-service-id': process.env.REACT_APP_X_SERVICE_ID
                    }
                };
                axios.post(process.env.REACT_APP_BE_URL, params, axiosConfig)
                    .then(result => {
                        const url = result.data.upload_url;
                        // after recieving the signed URL an upload via put is possible

                        const axiosUploadConfig = {
                            headers: {'content-disposition': 'Attachment', 'Content-Type': selectedFile.type},
                            onUploadProgress: (progressEvent) => {
                                const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                                if (totalLength !== null) {
                                    this.setState({dataTableUploadProgress: Math.round((progressEvent.loaded * 100) / totalLength)});
                                }
                            }
                        };
                        axios.put(url, selectedFile, axiosUploadConfig)
                            .then(result => {
                                this.messages.show({
                                    severity: 'success',
                                    summary: 'Success',
                                    detail: 'File is completely uploaded!'
                                });

                                this.setState({
                                    dataTableUploadInProgress: false,
                                    dataTableUploadProgress: 0.0,
                                    isFileUploaded: true,
                                    fileProcessing: true
                                });
                            })
                            .catch(err => {
                                this.setState({
                                    dataTableUploadInProgress: false,
                                    dataTableUploadProgress: 0.0
                                });
                                this.messages.show({severity: 'error', summary: 'Upload Error', detail: err.message});
                            });
                    })
                    .catch(err => {
                        this.messages.show({severity: 'error', summary: 'Upload Error', detail: err.message});
                    })
            })
            .catch(err => {
                this.setState({dataTableUploadInProgress: false});
                this.messages.show({severity: 'error', summary: 'Error', detail: err.message});
            });
    };


    render() {
        return (
            <main className="o-page-wrap">
                <div className="o-page-wrap">
                    <nav>
                        <ul className="c-breadcrumb">
                            <li><Link to="/"><i aria-hidden="true" className="c-icon c-icon--[house]"></i></Link></li>
                            <li><span>Upload Data</span></li>
                        </ul>
                    </nav>
                </div>
                <section className="o-content-section">
                    <div className="o-page-wrap ">
                        <h2>1. Upload Data</h2>
                        <p>Please upload a CSV document containing the training data for the pricing algorithm.</p>
                        {!this.state.isFileUploaded ?
                            <div>
                                <form action="#" name="fileform">
                                    <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item u-1/1">
                                                <input className="c-upload__input" type="file" id="file-check"
                                                       accept=".csv"
                                                       onChange={this.onDocumentSelected}
                                                       disabled={this.state.dataTableUploadInProgress}/>
                                                <label className="c-upload__button  c-btn" htmlFor="file-check">
                                                    <span>Upload</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <br/>
                            </div>
                            : null
                        }
                        { this.state.dataTableUploadInProgress ?
                            <ProgressBar value={this.state.dataTableUploadProgress}/>
                            : null
                        }

                        <Messages ref={(el) => this.messages = el}></Messages>

                        <h2>Uploaded Files</h2>
                        <p>The tool accepts one file for each day. Files uploaded at the same day are overwritten.</p>

                        <div>
                                {this.state.uploadedFiles.map((value, index) =>
                                    <UploadedFile key={index} file={value} />
                                )}
                        </div>

                    </div>
                </section>
            </main>
        );
    }
}

export default UploadCZ
